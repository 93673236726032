import React from 'react';
import LandingPage from "./components/landing-page";
import { BrowserRouter as Router, Route } from "react-router-dom";
import './assets/scss/app.scss';


// Handle the routing, Two optional parameters Region and Point, if both are unset then the home page is shown.
function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/:region?/:point?" component={LandingPage}></Route>
      </Router>
    </div>
  );
}

export default App;
