import React from "react";
import PropTypes from 'prop-types';
import AnimatedLink from "./animated-link";

class PointCard extends React.Component {
    constructor (props) {
        super();
    }

    state = {
    };

  
    render() {

        const point = this.props.point;

        return (
            <AnimatedLink pageChangeStatus={this.props.pageChangeStatus} className="point-card" to={`${this.props.region}/${this.props.id}`} delay={1000}>
                <figure>
                    {this.props.point.image && Array.isArray(this.props.point.image) ?
                        <img src={point.image[0].image} alt=""/>
                        :
                        <img src={point.image} alt=""/>
                    }
                </figure>
                <div className="copy">
                    <span className="label">solution</span>
                    <span className="button">View</span>
                    <h1>{point && point.title}</h1>    
                    <p>
                        {point && point.copy}
                    </p>
                </div>            
            </AnimatedLink>
        );
    }   
}

PointCard.propTypes = {
    point: PropTypes.object
}
 

export default PointCard;