import { createContext, Component, React } from 'react';

/**
 * React Context object containing our current geo region,
 * and an `updateGeoRegion` method for updating it.
 * Allows any component to easily access and/or update the current geo region.
 *
 * @category Contexts
 */
export const GeoContext = createContext({
	region: 'en',
	// Function default is empty, the true value gets set in `pages/_app.js`
	updateGeoRegion: () => {} // eslint-disable-line no-empty-function
});

/**
 * React Context object for storing our array of menus.
 * Allows any component to access the menu data without passing through props.
 *
 * @category Contexts
 */
export const MenusContext = createContext();

/**
 * React Context object for storing our ACF Global Options data.
 * Allows any component to access the global options without passing through props.
 *
 * @category Contexts
 */
export const OptionsContext = createContext();

/**
 * React Context object for storing our base WP Settings data.
 * Allows any component to access the WP settings without passing through props.
 *
 * @category Contexts
 */
export const SettingsContext = createContext();

/**
 * React Context object for storing a boolean value for if the current viewport is a mobile screen size or not.
 * Allows us to easily render different markup, content and even components for desktop vs mobile screens.
 *
 * @category Contexts
 */
export const MobileContext = createContext();

/**
 * React Context object containing the open state of our Newsletter Form component,
 * and the method for updating that state.
 * Allows us to easily trigger the Newsletter Form drawer from anywhere in the app.
 *
 * @category Contexts
 */
export const NewsletterContext = createContext({
	drawerOpen: false,
	toggleNewsletterDrawer: () => {} // eslint-disable-line no-empty-function
});

/**
 * React Context object containing the open state of our Register Form component,
 * and the method for updating that state.
 * Allows us to easily trigger the Register Form drawer from anywhere in the app.
 *
 * @category Contexts
 */
export const RegisterContext = createContext({
	drawerOpen: false,
	file: null,
	toggleRegisterDrawer: () => {} // eslint-disable-line no-empty-function
});

export const withAllContexts = (WrappedComponent) =>
	class WithAllContexts extends Component {

		render() {
			return (
				<MenusContext.Consumer>
					{(menus) =>
						<OptionsContext.Consumer>
							{(options) =>
								<SettingsContext.Consumer>
									{(settings) =>
										<NewsletterContext.Consumer>
											{(newsletter) =>
												<RegisterContext.Consumer>
													{(register) =>
														<WrappedComponent
															{ ...this.props }
															menus={ menus }
															options={ options }
															settings={ settings }
															newsletter={ newsletter }
															register={ register }
														/>
													}
												</RegisterContext.Consumer>
											}
										</NewsletterContext.Consumer>
									}
								</SettingsContext.Consumer>
							}
						</OptionsContext.Consumer>
					}
				</MenusContext.Consumer>
			)
		}

	}
