var DATA = {
	title: 'End-to-end Solutions',
	subtitle: 'Overview',
	formSiteUrl: 'https://www.daifukuatec.com',
	register_form: {
		title: 'Register to download',
		description:
			'Enter your details below and a brochure download link will be sent to your email.',
		labelPlacement: 'top_label',
		descriptionPlacement: 'below',
		button: {
			type: 'text',
			text: 'Submit',
			imageUrl: ''
		},
		fields: [
			{
				type: 'email',
				id: 1,
				label: 'Email address',
				adminLabel: '',
				isRequired: true,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				inputs: null,
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: '',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				choices: '',
				conditionalLogic: '',
				productField: '',
				emailConfirmEnabled: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			},
			{
				type: 'text',
				id: 4,
				label: 'First name',
				adminLabel: '',
				isRequired: true,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				inputs: null,
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: '',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				choices: '',
				conditionalLogic: '',
				productField: '',
				enablePasswordInput: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			},
			{
				type: 'text',
				id: 5,
				label: 'Last name',
				adminLabel: '',
				isRequired: true,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				inputs: null,
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: '',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				choices: '',
				conditionalLogic: '',
				productField: '',
				enablePasswordInput: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			},
			{
				type: 'checkbox',
				id: 3,
				label: 'Consent',
				adminLabel: '',
				isRequired: true,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				choices: [
					{
						text:
							"I agree to receive newsletters about Daifuku Airport Technologies' events and related promotions. You can unsubscribe at any time.",
						value:
							"I agree to receive newsletters about Daifuku Airport Technologies' events and related promotions. You can unsubscribe at any time.",
						isSelected: false,
						price: ''
					}
				],
				inputs: [
					{
						id: '3.1',
						label:
							"I agree to receive newsletters about Daifuku Airport Technologies' events and related promotions. You can unsubscribe at any time.",
						name: ''
					}
				],
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: 'hidden_label',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				conditionalLogic: '',
				productField: '',
				enableSelectAll: '',
				enablePrice: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				checkboxLabel: '',
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			},
			{
				type: 'hidden',
				id: 6,
				label: 'File',
				adminLabel: '',
				isRequired: false,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				inputs: null,
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: '',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				choices: '',
				conditionalLogic: '',
				productField: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			},
			{
				type: 'hidden',
				id: 7,
				label: 'File Name',
				adminLabel: '',
				isRequired: false,
				size: 'medium',
				errorMessage: '',
				visibility: 'visible',
				inputs: null,
				formId: 4,
				description: '',
				allowsPrepopulate: false,
				inputMask: false,
				inputMaskValue: '',
				inputMaskIsCustom: false,
				maxLength: '',
				inputType: '',
				labelPlacement: '',
				descriptionPlacement: '',
				subLabelPlacement: '',
				placeholder: '',
				cssClass: '',
				inputName: '',
				noDuplicates: false,
				defaultValue: '',
				choices: '',
				conditionalLogic: '',
				productField: '',
				multipleFiles: false,
				maxFiles: '',
				calculationFormula: '',
				calculationRounding: '',
				enableCalculation: '',
				disableQuantity: false,
				displayAllCategories: false,
				useRichTextEditor: false,
				pageNumber: 1,
				fields: '',
				displayOnly: ''
			}
		],
		version: '2.4.9',
		id: 4,
		nextFieldId: 8,
		useCurrentUserAsAuthor: true,
		postContentTemplateEnabled: false,
		postTitleTemplateEnabled: false,
		postTitleTemplate: '',
		postContentTemplate: '',
		lastPageButton: null,
		pagination: null,
		firstPageCssClass: null,
		subLabelPlacement: 'below',
		cssClass: '',
		enableHoneypot: false,
		enableAnimation: false,
		save: {
			enabled: false,
			button: {
				type: 'link',
				text: 'Save and Continue Later'
			}
		},
		limitEntries: false,
		limitEntriesCount: '',
		limitEntriesPeriod: '',
		limitEntriesMessage: '',
		scheduleForm: false,
		scheduleStart: '',
		scheduleStartHour: '',
		scheduleStartMinute: '',
		scheduleStartAmpm: '',
		scheduleEnd: '',
		scheduleEndHour: '',
		scheduleEndMinute: '',
		scheduleEndAmpm: '',
		schedulePendingMessage: '',
		scheduleMessage: '',
		requireLogin: false,
		requireLoginMessage: '',
		confirmations: {
			'5cabe4132805a': {
				id: '5cabe4132805a',
				name: 'Default Confirmation',
				isDefault: true,
				type: 'message',
				message:
					'<p class="body--eyebrow">Sent!</p><h5 class="heading--5">Check your email</h5><p class="body--2">The brochure has been sent to your inbox (you may need to check your spam folder)</p>',
				url: '',
				pageId: 0,
				queryString: '',
				disableAutoformat: false,
				conditionalLogic: []
			}
		},
		is_active: '1',
		date_created: '2019-04-09 00:15:15',
		is_trash: '0'
	},
	regions: [
		{
			title: 'Check-in',
			transform: 'rotate(0)',
			width: 222.93,
			left: '26.27',
			top: '42.9',
			points: [
				{
					title: 'Self Bag Drop',
					image: [
						{
							image:
								'https://www.daifukuatec.com/api/asset/2019/09/Bag_drop.jpg',
							copy:
								'Our Self Bag Drop (SBD) series enhances the passenger check-in experience and features a modern, flexible design that is capable of meeting the needs of any physical airport environment. The intuitive interface design delivers clear, graphical prompts,facilitating a seamless check-in process. Our  SBD series is the only solution certified for use on the world’s leading CUSS platform.'
						},
						{
							image:
								'https://www.daifukuatec.com/api/asset/2019/09/Bag_drop_2.jpg',
							copy:
								'Our Self Bag Drop (SBD) series enhances the passenger check-in experience and features a modern, flexible design that is capable of meeting the needs of any physical airport environment. The intuitive interface design delivers clear, graphical prompts,facilitating a seamless check-in process. Our  SBD series is the only solution certified for use on the world’s leading CUSS platform.'
                        },
                        {
                            image: 'https://www.daifukuatec.com/api/asset/2019/10/Bag_Drop_scarabee.jpg',
                            copy:
								'Our Self Bag Drop (SBD) series enhances the passenger check-in experience and features a modern, flexible design that is capable of meeting the needs of any physical airport environment. The intuitive interface design delivers clear, graphical prompts,facilitating a seamless check-in process. Our  SBD series is the only solution certified for use on the world’s leading CUSS platform.'
                        },
                        {
                            image: 'https://www.daifukuatec.com/api/asset/2019/10/Bag_drop_lite.jpg',
                            copy:
								'Our Self Bag Drop (SBD) series enhances the passenger check-in experience and features a modern, flexible design that is capable of meeting the needs of any physical airport environment. The intuitive interface design delivers clear, graphical prompts,facilitating a seamless check-in process. Our  SBD series is the only solution certified for use on the world’s leading CUSS platform.'
                        }
					],
					x: 10,
					y: 38,
					copy:
						'Our Self Bag Drop (SBD) series enhances the passenger check-in experience and features a modern, flexible design that is capable of meeting the needs of any physical airport environment. The intuitive interface design delivers clear, graphical prompts,facilitating a seamless check-in process. Our  SBD series is the only solution certified for use on the world’s leading CUSS platform.',
					products: [
						{
							icon: 'sbd-1',
							title: 'A modern, flexible design',
							copy:
								'Built to meet the unique physical requirements, objectives and aesthetics of airports worldwide.'
						},
						{
							icon: 'sbd-3',
							title: 'Unprecedented efficiency',
							copy:
								'Our range features clear, graphical prompts—facilitating a seamless check-in process.'
						},
						{
							icon: 'sbd-4',
							title: 'A common-use approach',
							copy:
								"Only Self Bag Drop solution certified for use on the world's leading common-use self-service (CUSS) platform."
						},
						{
							icon: 'sbd-2',
							title: 'Unparalleled data analytics',
							copy:
								'Next generation reporting and business intelligence, offering unprecedented access and control.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Self-Bag-Drop-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Self Bag Drop Solutions Brochure',
						title: 'Self Bag Drop Solutions Brochure'
					}
				},
				{
					title: 'Conveyors',
					image:
						'https://www.daifukuatec.com/api/asset/2019/10/Check_in_conveyor.jpg',
					x: 34,
					y: 18,
					copy:
						'The check-in conveyor is the primary hardware unit between passengers and the baggage handling system. Our innovative design comes standard with a variety of features.',
					products: [
						{
							icon: 'sbd-1',
							title: 'Adjustable design',
							copy:
								'Adaptable aesthetics to suit all airport environments with automatic bag weighing and dispatch onto take-away systems.'
						},
						{
							icon: 'ebs-2',
							title: 'People orientated',
							copy:
								'Tub load feature, available in two, three and four stage design—reducing check-in personnel heavy lifting, also eliminating trap points for fingers, straps and labels.'
						},
						{
							icon: 'sortation-4',
							title: 'Improved maintenance',
							copy:
								'Removable side panels for easy maintenance with roll-out system providing easy access for cleaning.'
						},
						{
							icon: 'ssl-3',
							title: 'Flexible approach',
							copy:
								'Adaptable operation to suit remote and self-service check-in.'
						}
					]
				},
				{
					title: 'Sym3',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Sym3.jpg',
					x: 62,
					y: 50,
					copy:
						'Sym3 Operator provides an end-to-end SCADA/Maintenance Diagnostic System package, specifically designed for use with BHS. A stand-out within the industry, Sym3 delivers a detailed 3D visualisation in real-time, accurately tracking baggage through the BHS, also monitoring hardware status. Used for simulation and emulation during projects, this 3D model is also used strategically for SCADA.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Enhanced tracking accuracy',
							copy:
								'Personnel can filter, search and highlight bags based on a range of criteria including; barcodes/PAX, carrier, flight number, EDT and make-up destination.'
						},
						{
							icon: 'sym3-3',
							title: 'Unprecedented efficiency',
							copy:
								'Operators are able to dramatically reduce levels of lost baggage and system downtime, while increasing efficiency and risk management.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time monitoring',
							copy:
								'Access to live data to detect abnormal behaviour early, avoiding issues before they happen. '
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Sym3 is accessible from any mobile or desktop device via a web browser.'
						}
					],
					brochure: {
						id: 2,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Sym3-Operator-Brochure-Oct-2019.pdf',
						alt: 'Sym3 Operator Brochure',
						title: 'Sym3 Operator Brochure'
					}
				}
			]
		},
		{
			title: 'Checkpoint Screening',
			transform: '0',
			width: 237.44,
			left: '19.4',
			top: '25.61',
			points: [
				{
					title: 'Smart Security Lanes',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Smart_security_lanes.jpg',
					x: 50.8,
					y: 36,
					copy:
						'SSLs deliver unparalleled passenger throughput with an unintrusive, ergonomic design, and are the first solution in-market that allow passengers to leave laptops and liquids inside their baggage during screening. SSLs reduce passenger wait times, while automatically separating approved and rejected baggage. The ability to segment cleared baggage allows security agents to spend more time assessing potential security risks, while other staff focus on service oriented tasks—enhancing the passenger experience.',
					products: [
						{
							icon: 'ssl-1',
							title: 'Advanced technology',
							copy:
								'Using smart, innovative screening software, threats are can be automatically detected.'
						},
						{
							icon: 'ssl-3',
							title: 'Enhanced passenger throughput',
							copy:
								'Passengers can collect their baggage from three locations, reducing excessive wait times.'
						},
						{
							icon: 'ssl-2',
							title: 'Unparalleled security',
							copy:
								'Designed to meet the highest EU and TSA standards.'
						},
						{
							icon: 'ssl-4',
							title: 'Seamless integration',
							copy:
								'CT technology integrates seamlessly with Smart Security Lanes (SSL) and remote screening solutions.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				}
			]
		},
		{
			title: 'Baggage Handling Solutions',
			transform: '0',
			width: '533.39',
			left: '10.2',
			top: '30.6',
			points: [
				{
					title: 'Security Screening',
					image:
						[
							{
                                image: 'https://www.daifukuatec.com/api/asset/2019/09/Security_screening.jpg',
                                title: 'MIT',
                                copy: 'The MIT is designed for use inside airport checked baggage reconciliation areas (CBRAs) and implemented as a replacement to traditional belt conveyors and static search tables. Using leading-edge robotic technology, MITs autonomously deliver baggage between conveyors and transport security officers, who can conduct bag searches directly on top of each unit’s stainless steel table top. '
                            },
                            {
                                image: 'https://www.daifukuatec.com/api/asset/2019/10/Security-screening.jpg',
                                copy: 'Our suite of security screening solutions have been designed to ensure the efficient sortation of passenger baggage, while maintaining absolute compliance for airport security; meeting TSA, CATSA and ECAC Standard 3 applications in any region. Our solutions include bag screening (HBS/EDS/CBS), Mobile Inspection Table (MIT), RFID and Standard 3 integration.'
                            }                            
                        ],
					x: 70,
					y: 45,
					copy:
						'Our suite of security screening solutions have been designed to ensure the efficient sortation of passenger baggage, while maintaining absolute compliance for airport security; meeting TSA, CATSA and ECAC Standard 3 applications in any region. Our solutions include bag screening (HBS/EDS/CBS), Mobile Inspection Table (MIT), RFID and Standard 3 integration.',
					products: [
						{
							icon: 'security-1',
							title: 'Unparalleled performance and security',
							copy:
								'Efficient sortation of baggage while maintaining airport security compliance.'
						},
						{
							icon: 'security-3',
							title: 'Enhanced efficiency',
							copy:
								'Seamless operation, streamlining the baggage delivery process—avoiding manual lifting and dragging.'
						},
						{
							icon: 'security-2',
							title: 'Reduced operating costs',
							copy:
								'Significant energy savings, low maintenance costs and reduced system downtime.'
						},
						{
							icon: 'security-4',
							title: 'Standards compliance',
							copy:
								'Our security screening solutions comply with TSA, CATSA and ECAC Standard 3 applications in every region.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/07/Mobile-Inspection-Table-Brochure-1.pdf',
						alt: 'Mobile Inspection Table Brochure',
						title: 'Mobile Inspection Table Brochure'
					}
				},
				{
					title: 'Bag Storage',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Early_bag_storage.jpg',
					x: 90,
					y: 35,
					copy:
						'Our bag storage solutions are used to store and release bags that have been delivered to the airport early, with capacity to hold bags indefinitely, enhancing operations at the makeup stage. We offer a range of technologies in addition to standard conveyor-based bag storage, including Baggage Tray System (BTS) lane-based storage and high-speed AS/RS. ',
					products: [
						{
							icon: 'ebs-1',
							title: 'A modern, flexible design',
							copy:
								'Our bag storage solutions can be adapted to suit the operational needs of all airports globally.'
						},
						{
							icon: 'ebs-2',
							title: 'Complete traceability',
							copy:
								'COPRFID technology facilitates 100% tracking and traceability throughout the BHS, reducing operational issues.'
						},
						{
							icon: 'ebs-3',
							title: 'Dynamic storage capabilities',
							copy:
								'Automated Storage and Retrieval Systems (AS/RS) can be used in conjunction with lane-based storage systems, facilitating efficient vertical storage.'
						},
						{
							icon: 'ebs-4',
							title: 'Unprecedented efficiency',
							copy:
								'Bag storage solutions can be integrated with the Baggage Tray System—one of the world’s fastest baggage transportation solutions.'
						}
					]
				},
				{
					title: 'Sortation',
					image: [
                        {
                            image: 'https://www.daifukuatec.com/api/asset/2019/10/Baggage_tray_system.jpg',
							copy:
								'We are a leading provider of efficient, high-speed, reliable and accurate solutions for the transportation and sortation of baggage from check-in counters to make-up areas. Our range has the unique ability to mix standard and oversized baggage, including BTS, Tilt Tray Sorter (TTS) and vertical sorting unit (VSU). Unlike conventional systems, our BTS transports bags on individual trays equipped with RFID tags facilitating 100% tracking and traceability. '
                        },
                        {
							image:
                                'https://www.daifukuatec.com/api/asset/2019/09/Sortation_2_TTS.jpg',
                            title: 'Tilt Tray Sorter ST100',
							copy:
								'Our ST100 WiFi-enabled TTS operates at a high speed, paired with intelligent features to maximise baggage throughput at very low operating costs. Our patented technology delivers high levels of efficiency, preventing bags from being inducted together or between trays. Our TTS utilises dynamic speed change technology to reduce component wear and operating costs, featuring built-in predictive maintenance attributes with condition-based monitoring.'
                        },
						{
							image:
                                'https://www.daifukuatec.com/api/asset/2019/09/Sortation-1.jpg',
                            title: 'Vertical Sorting Unit',
							copy:
								'The vertical sorting unit has the ability to divide the tray flow into two vertically separated paths, based on individual tray or route selection. Its compact, low-impact design allows it to be incorporated within the BTS of any airport, accommodating both regular and fragile items.'
						}						                       
					],
					x: 30,
					y: 50,
					copy:
						'We are a leading provider of efficient, high-speed, reliable and accurate solutions for the transportation and sortation of baggage from check-in counters to make-up areas. Our range has the unique ability to mix standard and oversized baggage, including BTS, Tilt Tray Sorter (TTS) and vertical sorting unit (VSU). Unlike conventional systems, our BTS transports bags on individual trays equipped with RFID tags facilitating 100% tracking and traceability.',
					products: [
						{
							icon: 'control-2',
							title: 'Dynamic handling capacity',
							copy:
								'Our sortation solutions have the unique ability to mix standard and oversized baggage.'
						},
						{
							icon: 'sortation-2',
							title: 'Accurate bag tracking',
							copy:
								'Using RFID technology, bags can be tracked throughout the BHS with absolute precision—eliminating occurrences of lost/misplaced baggage.'
						},
						{
							icon: 'sortation-3',
							title: 'Unparalleled efficiency',
							copy:
								'Our Baggage Tray System (BTS) is among the world’s fastest, and capable of conveying bags at speeds of up to 10 m/s.'
						},
						{
							icon: 'sortation-4',
							title: 'Improved system maintenance',
							copy:
								'Predictive maintenance scheduling with ‘plug and play’ design—key maintenance elements are fitted with plug and socket panels to reduce downtime. '
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Tilt-Tray-Sorter-ST100-Brochure-Oct-2019.pdf',
						alt: 'Tilt Tray Sorter ST100 Brochure',
						title: 'Tilt Tray Sorter ST100 Brochure'
					}
				},
				{
					title: 'Control Systems',
					image: [
						{
							image:
								'https://www.daifukuatec.com/api/asset/2019/09/Sym3.jpg',
							// title: "Sub Heading",
							copy:
								'Our BHS management suite is among the world’s most advanced, featuring our sort allocation controller, Airflow, and Baggage Handling Control System, Webbview. Both products include all modules necessary to manage both simple and complex BHS solutions in full compliance with IATA 753.  The active routing system ensures optimal performance under all conditions, with features including; live bag redirection, continuous path assessment, dynamic BTS routing and empty tray management , facilitating the delivery of baggage on conventional conveyors, TTS, BTS and hybrid sortation systems with ease.'
						},
						{
							image:
								'https://www.daifukuatec.com/api/asset/2019/09/Webbview.jpg',
							copy:
								'Our BHS management suite is among the world’s most advanced, featuring our sort allocation controller, Airflow, and Baggage Handling Control System, Webbview. Both products include all modules necessary to manage both simple and complex BHS solutions in full compliance with IATA 753.  The active routing system ensures optimal performance under all conditions, with features including; live bag redirection, continuous path assessment, dynamic BTS routing and empty tray management , facilitating the delivery of baggage on conventional conveyors, TTS, BTS and hybrid sortation systems with ease.'
						}
					],
					x: 80,
					y: 15,
					copy:
						'Our BHS management suite is among the world’s most advanced, featuring our sort allocation controller, Airflow, and Baggage Handling Control System, Webbview. Both products include all modules necessary to manage both simple and complex BHS solutions in full compliance with IATA 753.  The active routing system ensures optimal performance under all conditions, with features including; live bag redirection, continuous path assessment, dynamic BTS routing and empty tray management , facilitating the delivery of baggage on conventional conveyors, TTS, BTS and hybrid sortation systems with ease.',
					products: [
						{
							icon: 'control-1',
							title: 'Integrated redundancy architecture',
							copy:
								'Our scalable design can be adapted to any BHS with established protocols, helping avoid single points of failure.'
						},
						{
							icon: 'control-3',
							title: 'Comprehensive business intelligence',
							copy:
								'Intelligent reporting and BI allow operators to view a range of web-based reports, monitor critical metrics via real-time dashboards and conduct in-depth analysis of historical data.'
						},
						{
							icon: 'control-2',
							title: 'Flexible sortation options',
							copy:
								'Facilitates bag sortation, including flight, sort assignments, carrier sorting and automatic rerouting of late baggage.'
						},
						{
							icon: 'control-4',
							title: 'Sym3 integration',
							copy:
								'Integrates seamlessly with Sym3 to show real-time bag drop volumes, allowing operators to visually assess system load.'
						}
					]
				},
				{
					title: 'Operations and Maintenance',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Operations_and_maintenance.jpg',
					x: 10,
					y: 45,
					copy:
						'Our operations and maintenance teams deliver value for our customers by deeply understanding their needs, developing meaningful partnerships and tailoring maintenance programs to meet the unique needs—helping them achieve their strategic objectives. Together, we ensure all mission critical systems operate at peak efficiency with maximum uptime.',
					products: [
						{
							icon: 'oem-1a',
							title: 'Passenger boarding bridges',
							copy:
								'With our proven maintenance approach, we reduce PBBs total cost of ownership while extending the total useful life at any stage.'
						},
						{
							icon: 'oem-2a',
							title: 'Baggage handling system',
							copy:
								'Our 24-hour, 7 days a week, 365 days a year turn-key services satisfy the most rigorous operation and maintenance specifications.'
						},
						{
							icon: 'oem-3a',
							title: 'Ground support equipment',
							copy:
								'With experience on over 12,00 pieces of GSE, our team ensures all equipment operates at peak efficiency with maximum uptime, while extending the life-cycle of assets through rigorous testing and preventative maintenance measures.'
						},
						{
							icon: 'oem-4a',
							title: 'Facilities maintenance',
							copy:
								'We institute and manage quality program policies and procedures for customer-owned and leased facilities, managing the maintenance of millions of square feet.'
						}
					]
				}
			]
		},
		{
			title: 'Retail',
			transform: '0',
			width: '120.2',
			left: '23.2',
			top: '14.1',
			points: [
				{
					title: 'RapidAIMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidAIMS.jpg',
					x: 50,
					y: 45,
					pin: 'rapid',
					copy:
						'RapidAIMS is an Airport Operational Database and the core of our turnkey airport management solutions, featuring real-time flight and terminal data display, broadcast services and statistical analysis. RapidAIMS can be scaled to suit airports of any size and offers industry-leading integration of flight, passenger and retail data, with secure accessibility for use by all stakeholders.',
					products: [
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with our suite of Rapid product solutions.'
						},
						{
							icon: 'sbd-4',
							title: 'Optimised resource utilisation',
							copy:
								'Improved planning, scheduling and administrative capabilities, increasing the efficiency of airport logistics and passenger flow.'
						},
						{
							icon: 'sym3-4',
							title: 'User-friendly interface',
							copy:
								'Intuitive graphical interface, featuring online help using Windows™ operating systems.'
						},
						{
							icon: 'ebs-2',
							title: 'Flexible, modular approach',
							copy:
								'Modules are included based on operational needs, with the ability to add additional modules as needed.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidFIDS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidFIDS.jpg',
					pin: 'rapid',
					copy:
						'RapidFIDS is an industry-leading Flight Information Display Solution, offering real-time display of information across flights, check-in counters, boarding gates, visual paging, carousels and more. With advanced rich-media capabilities, RapidFIDS gives our partners complete control over what they communicate to passengers and when—enhancing the passenger experience.',
					products: [
						{
							icon: 'sortation-2',
							title: 'Live information display',
							copy:
								'Critical airport information is displayed in real-time, enhancing the passenger experience.'
						},
						{
							icon: 'sb2-1',
							title: 'A dynamic, flexible approach',
							copy:
								'Page maintenance utility allows personnel to format the type and look of data displayed.'
						},
						{
							icon: 'ssl-1',
							title: 'Simple integration',
							copy:
								'Easily interfaced with other airport systems and applications, including CUTE terminals, airline and handler systems, BHS, air traffic control, security and more.'
						},
						{
							icon: 'control-4',
							title: 'Redundancy architecture',
							copy:
								'Device maintenance utility monitors and maintains hardware and software throughout the network, preventing system failures.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidRMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidRMS.jpg',
					pin: 'rapid',
					copy:
						'RapidRMS offers strategic planning and decision support in real-time for critical activities including; allocation of gates, parking position, check-in counters and baggage carousels. Featuring an intuitive graphical interface, RapidRMS enhances the efficiency with which airport operators can carry out their role, while helping eliminate possible sources of error.',
					products: [
						{
							icon: 'ssl-3',
							title: 'Enhanced passenger service',
							copy:
								'Personnel and passengers are kept up-to-date without delay or double-entry, enhancing the passenger experience.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time data',
							copy:
								'Reduces the risk of error through redundant information and centralised validation.'
						},
						{
							icon: 'rapid-3',
							title: 'User-friendly interface',
							copy:
								'Intuitive, graphical interface ensures operators are kept up-to-date on all critical data, highlighting any planning conflicts or errors.'
						},
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with RapidAIMS, ensuring rules are centrally managed and applied across all airport systems.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidACIS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidACIS.jpg',
					pin: 'rapid',
					copy:
						'RapidACIS is a dynamic, configurable Airport Commercial Information System. Strategically gathering information on passenger flow patterns and retail performance, RapidACIS helps create powerful, precise commercial decisions that increase revenue. Using RapidACIS, airports and their tenants can assess commercial opportunities and enhance their strategic sales planning process.',
					products: [
						{
							icon: 'sbd-3',
							title: 'Relevant, accurate passenger data',
							copy:
								'Provides a total and immediate picture of passenger flows and spending to all stakeholders.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Automated reporting based on accumulated data, featuring a clear, graphical display.'
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Data is accessible to all authorised airport and retail personnel.'
						},
						{
							icon: 'sym3-1',
							title: 'Targeted messaging',
							copy:
								'Multilingual information and services can be efficiently delivered to targeted audiences.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidADS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidADS.jpg',
					pin: 'rapid',
					copy:
						'RapidADS is a revolutionary Advertising Display Solution, designed to increase airport revenue. Leveraging existing display infrastructure and using ‘smart-zoned’ advertising, RapidADS targets promotions towards specific demographics across various areas of the airport. Using RapidADS, airports can open new revenue streams and more effectively engage their commercial audience.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Timely, compelling messaging',
							copy:
								'Delivering relevant information to passengers at the point of consumption drives commercial results and operational efficiencies.'
						},
						{
							icon: 'oem-1',
							title: 'Increased revenue',
							copy:
								'Targeted commercial messaging unlocks previously dormant revenue streams.'
						},
						{
							icon: 'ssl-1',
							title: 'RapidFIDS integration',
							copy:
								'Enables rich-media messaging to be displayed across existing electronic mediums.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Information regarding advertising accounts is tracked in real-time and converted into clear, graphical reports.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				}
			]
		},
		{
			title: 'Departures',
			transform: '0',
			width: '328.78',
			left: '29.2',
			top: '5.6',
			points: [
				{
					title: 'Sym3',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Sym3.jpg',
					x: 62,
					y: 50,
					copy:
						'Sym3 Operator provides an end-to-end SCADA/Maintenance Diagnostic System package, specifically designed for use with BHS. A stand-out within the industry, Sym3 delivers a detailed 3D visualisation in real-time, accurately tracking baggage through the BHS, also monitoring hardware status. Used for simulation and emulation during projects, this 3D model is also used strategically for SCADA.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Enhanced tracking accuracy',
							copy:
								'Personnel can filter, search and highlight bags based on a range of criteria including; barcodes/PAX, carrier, flight number, EDT and make-up destination.'
						},
						{
							icon: 'sym3-3',
							title: 'Unprecedented efficiency',
							copy:
								'Operators are able to dramatically reduce levels of lost baggage and system downtime, while increasing efficiency and risk management.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time monitoring',
							copy:
								'Access to live data to detect abnormal behaviour early, avoiding issues before they happen. '
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Sym3 is accessible from any mobile or desktop device via a web browser.'
						}
					],
					brochure: {
						id: 2,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Sym3-Operator-Brochure-Oct-2019.pdf',
						alt: 'Sym3 Operator Brochure',
						title: 'Sym3 Operator Brochure'
					}
				},
				{
					title: 'RapidAIMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidAIMS.jpg',
					x: 10,
					y: 45,
					pin: 'rapid',
					copy:
						'RapidAIMS is an Airport Operational Database and the core of our turnkey airport management solutions, featuring real-time flight and terminal data display, broadcast services and statistical analysis. RapidAIMS can be scaled to suit airports of any size and offers industry-leading integration of flight, passenger and retail data, with secure accessibility for use by all stakeholders.',
					products: [
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with our suite of Rapid product solutions.'
						},
						{
							icon: 'sbd-4',
							title: 'Optimised resource utilisation',
							copy:
								'Improved planning, scheduling and administrative capabilities, increasing the efficiency of airport logistics and passenger flow.'
						},
						{
							icon: 'sym3-4',
							title: 'User-friendly interface',
							copy:
								'Intuitive graphical interface, featuring online help using Windows™ operating systems.'
						},
						{
							icon: 'ebs-2',
							title: 'Flexible, modular approach',
							copy:
								'Modules are included based on operational needs, with the ability to add additional modules as needed.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidFIDS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidFIDS.jpg',
					pin: 'rapid',
					copy:
						'RapidFIDS is an industry-leading Flight Information Display Solution, offering real-time display of information across flights, check-in counters, boarding gates, visual paging, carousels and more. With advanced rich-media capabilities, RapidFIDS gives our partners complete control over what they communicate to passengers and when—enhancing the passenger experience.',
					products: [
						{
							icon: 'sortation-2',
							title: 'Live information display',
							copy:
								'Critical airport information is displayed in real-time, enhancing the passenger experience.'
						},
						{
							icon: 'sb2-1',
							title: 'A dynamic, flexible approach',
							copy:
								'Page maintenance utility allows personnel to format the type and look of data displayed.'
						},
						{
							icon: 'ssl-1',
							title: 'Simple integration',
							copy:
								'Easily interfaced with other airport systems and applications, including CUTE terminals, airline and handler systems, BHS, air traffic control, security and more.'
						},
						{
							icon: 'control-4',
							title: 'Redundancy architecture',
							copy:
								'Device maintenance utility monitors and maintains hardware and software throughout the network, preventing system failures.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidRMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidRMS.jpg',
					pin: 'rapid',
					copy:
						'RapidRMS offers strategic planning and decision support in real-time for critical activities including; allocation of gates, parking position, check-in counters and baggage carousels. Featuring an intuitive graphical interface, RapidRMS enhances the efficiency with which airport operators can carry out their role, while helping eliminate possible sources of error.',
					products: [
						{
							icon: 'ssl-3',
							title: 'Enhanced passenger service',
							copy:
								'Personnel and passengers are kept up-to-date without delay or double-entry, enhancing the passenger experience.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time data',
							copy:
								'Reduces the risk of error through redundant information and centralised validation.'
						},
						{
							icon: 'rapid-3',
							title: 'User-friendly interface',
							copy:
								'Intuitive, graphical interface ensures operators are kept up-to-date on all critical data, highlighting any planning conflicts or errors.'
						},
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with RapidAIMS, ensuring rules are centrally managed and applied across all airport systems.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidACIS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidACIS.jpg',
					pin: 'rapid',
					copy:
						'RapidACIS is a dynamic, configurable Airport Commercial Information System. Strategically gathering information on passenger flow patterns and retail performance, RapidACIS helps create powerful, precise commercial decisions that increase revenue. Using RapidACIS, airports and their tenants can assess commercial opportunities and enhance their strategic sales planning process.',
					products: [
						{
							icon: 'sbd-3',
							title: 'Relevant, accurate passenger data',
							copy:
								'Provides a total and immediate picture of passenger flows and spending to all stakeholders.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Automated reporting based on accumulated data, featuring a clear, graphical display.'
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Data is accessible to all authorised airport and retail personnel.'
						},
						{
							icon: 'sym3-1',
							title: 'Targeted messaging',
							copy:
								'Multilingual information and services can be efficiently delivered to targeted audiences.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidADS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidADS.jpg',
					pin: 'rapid',
					copy:
						'RapidADS is a revolutionary Advertising Display Solution, designed to increase airport revenue. Leveraging existing display infrastructure and using ‘smart-zoned’ advertising, RapidADS targets promotions towards specific demographics across various areas of the airport. Using RapidADS, airports can open new revenue streams and more effectively engage their commercial audience.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Timely, compelling messaging',
							copy:
								'Delivering relevant information to passengers at the point of consumption drives commercial results and operational efficiencies.'
						},
						{
							icon: 'oem-1',
							title: 'Increased revenue',
							copy:
								'Targeted commercial messaging unlocks previously dormant revenue streams.'
						},
						{
							icon: 'ssl-1',
							title: 'RapidFIDS integration',
							copy:
								'Enables rich-media messaging to be displayed across existing electronic mediums.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Information regarding advertising accounts is tracked in real-time and converted into clear, graphical reports.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				}
			]
		},
		{
			title: 'Arrivals',
			transform: '0',
			width: '328.78',
			left: '4.2',
			top: '5.6',
			points: [
				{
					title: 'Sym3',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Sym3.jpg',
					x: 62,
					y: 50,
					copy:
						'Sym3 Operator provides an end-to-end SCADA/Maintenance Diagnostic System package, specifically designed for use with BHS. A stand-out within the industry, Sym3 delivers a detailed 3D visualisation in real-time, accurately tracking baggage through the BHS, also monitoring hardware status. Used for simulation and emulation during projects, this 3D model is also used strategically for SCADA.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Enhanced tracking accuracy',
							copy:
								'Personnel can filter, search and highlight bags based on a range of criteria including; barcodes/PAX, carrier, flight number, EDT and make-up destination.'
						},
						{
							icon: 'sym3-3',
							title: 'Unprecedented efficiency',
							copy:
								'Operators are able to dramatically reduce levels of lost baggage and system downtime, while increasing efficiency and risk management.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time monitoring',
							copy:
								'Access to live data to detect abnormal behaviour early, avoiding issues before they happen. '
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Sym3 is accessible from any mobile or desktop device via a web browser.'
						}
					],
					brochure: {
						id: 2,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Sym3-Operator-Brochure-Oct-2019.pdf',
						alt: 'Sym3 Operator Brochure',
						title: 'Sym3 Operator Brochure'
					}
				},
				{
					title: 'RapidAIMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidAIMS.jpg',
					x: 10,
					y: 45,
					pin: 'rapid',
					copy:
						'RapidAIMS is an Airport Operational Database and the core of our turnkey airport management solutions, featuring real-time flight and terminal data display, broadcast services and statistical analysis. RapidAIMS can be scaled to suit airports of any size and offers industry-leading integration of flight, passenger and retail data, with secure accessibility for use by all stakeholders.',
					products: [
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with our suite of Rapid product solutions.'
						},
						{
							icon: 'sbd-4',
							title: 'Optimised resource utilisation',
							copy:
								'Improved planning, scheduling and administrative capabilities, increasing the efficiency of airport logistics and passenger flow.'
						},
						{
							icon: 'sym3-4',
							title: 'User-friendly interface',
							copy:
								'Intuitive graphical interface, featuring online help using Windows™ operating systems.'
						},
						{
							icon: 'ebs-2',
							title: 'Flexible, modular approach',
							copy:
								'Modules are included based on operational needs, with the ability to add additional modules as needed.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidFIDS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidFIDS.jpg',
					pin: 'rapid',
					copy:
						'RapidFIDS is an industry-leading Flight Information Display Solution, offering real-time display of information across flights, check-in counters, boarding gates, visual paging, carousels and more. With advanced rich-media capabilities, RapidFIDS gives our partners complete control over what they communicate to passengers and when—enhancing the passenger experience.',
					products: [
						{
							icon: 'sortation-2',
							title: 'Live information display',
							copy:
								'Critical airport information is displayed in real-time, enhancing the passenger experience.'
						},
						{
							icon: 'sb2-1',
							title: 'A dynamic, flexible approach',
							copy:
								'Page maintenance utility allows personnel to format the type and look of data displayed.'
						},
						{
							icon: 'ssl-1',
							title: 'Simple integration',
							copy:
								'Easily interfaced with other airport systems and applications, including CUTE terminals, airline and handler systems, BHS, air traffic control, security and more.'
						},
						{
							icon: 'control-4',
							title: 'Redundancy architecture',
							copy:
								'Device maintenance utility monitors and maintains hardware and software throughout the network, preventing system failures.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidRMS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidRMS.jpg',
					pin: 'rapid',
					copy:
						'RapidRMS offers strategic planning and decision support in real-time for critical activities including; allocation of gates, parking position, check-in counters and baggage carousels. Featuring an intuitive graphical interface, RapidRMS enhances the efficiency with which airport operators can carry out their role, while helping eliminate possible sources of error.',
					products: [
						{
							icon: 'ssl-3',
							title: 'Enhanced passenger service',
							copy:
								'Personnel and passengers are kept up-to-date without delay or double-entry, enhancing the passenger experience.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time data',
							copy:
								'Reduces the risk of error through redundant information and centralised validation.'
						},
						{
							icon: 'rapid-3',
							title: 'User-friendly interface',
							copy:
								'Intuitive, graphical interface ensures operators are kept up-to-date on all critical data, highlighting any planning conflicts or errors.'
						},
						{
							icon: 'rapid-1',
							title: 'Seamless integration',
							copy:
								'Designed to operate seamlessly with RapidAIMS, ensuring rules are centrally managed and applied across all airport systems.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidACIS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidACIS.jpg',
					pin: 'rapid',
					copy:
						'RapidACIS is a dynamic, configurable Airport Commercial Information System. Strategically gathering information on passenger flow patterns and retail performance, RapidACIS helps create powerful, precise commercial decisions that increase revenue. Using RapidACIS, airports and their tenants can assess commercial opportunities and enhance their strategic sales planning process.',
					products: [
						{
							icon: 'sbd-3',
							title: 'Relevant, accurate passenger data',
							copy:
								'Provides a total and immediate picture of passenger flows and spending to all stakeholders.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Automated reporting based on accumulated data, featuring a clear, graphical display.'
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Data is accessible to all authorised airport and retail personnel.'
						},
						{
							icon: 'sym3-1',
							title: 'Targeted messaging',
							copy:
								'Multilingual information and services can be efficiently delivered to targeted audiences.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				},
				{
					title: 'RapidADS',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/RapidADS.jpg',
					pin: 'rapid',
					copy:
						'RapidADS is a revolutionary Advertising Display Solution, designed to increase airport revenue. Leveraging existing display infrastructure and using ‘smart-zoned’ advertising, RapidADS targets promotions towards specific demographics across various areas of the airport. Using RapidADS, airports can open new revenue streams and more effectively engage their commercial audience.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Timely, compelling messaging',
							copy:
								'Delivering relevant information to passengers at the point of consumption drives commercial results and operational efficiencies.'
						},
						{
							icon: 'oem-1',
							title: 'Increased revenue',
							copy:
								'Targeted commercial messaging unlocks previously dormant revenue streams.'
						},
						{
							icon: 'ssl-1',
							title: 'RapidFIDS integration',
							copy:
								'Enables rich-media messaging to be displayed across existing electronic mediums.'
						},
						{
							icon: 'rapid-4',
							title: 'Intuitive reporting system',
							copy:
								'Information regarding advertising accounts is tracked in real-time and converted into clear, graphical reports.'
						}
					],
					brochure: {
						id: 1,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Daifuku-Digital-Solutions-Brochure-Oct-2019.pdf',
						alt: 'Digital Solutions Brochure',
						title: 'Digital Solutions Brochure'
					}
				}
			]
		},
		{
			title: 'Baggage Reclaim',
			transform: '0',
			width: '205.06',
			left: '13.5',
			top: '42.9',
			points: [
				{
					title: 'Sym3',
					image:
						'https://www.daifukuatec.com/api/asset/2019/09/Sym3.jpg',
					x: 75,
					y: 27,
					copy:
						'Sym3 Operator provides an end-to-end SCADA/Maintenance Diagnostic System package, specifically designed for use with BHS. A stand-out within the industry, Sym3 delivers a detailed 3D visualisation in real-time, accurately tracking baggage through the BHS, also monitoring hardware status. Used for simulation and emulation during projects, this 3D model is also used strategically for SCADA.',
					products: [
						{
							icon: 'sym3-1',
							title: 'Enhanced tracking accuracy',
							copy:
								'Personnel can filter, search and highlight bags based on a range of criteria including; barcodes/PAX, carrier, flight number, EDT and make-up destination.'
						},
						{
							icon: 'sym3-3',
							title: 'Unprecedented efficiency',
							copy:
								'Operators are able to dramatically reduce levels of lost baggage and system downtime, while increasing efficiency and risk management.'
						},
						{
							icon: 'sym3-2',
							title: 'Real-time monitoring',
							copy:
								'Access to live data to detect abnormal behaviour early, avoiding issues before they happen. '
						},
						{
							icon: 'sym3-4',
							title: 'Universal access',
							copy:
								'Sym3 is accessible from any mobile or desktop device via a web browser.'
						}
					],
					brochure: {
						id: 2,
						url:
							'https://www.daifukuatec.com/api/asset/2019/10/Sym3-Operator-Brochure-Oct-2019.pdf',
						alt: 'Sym3 Operator Brochure',
						title: 'Sym3 Operator Brochure'
					}
				}
			]
		}
	]
};

export default DATA