import React from "react";
import PropTypes from "prop-types";
import PointIndex from "./point-index";
import PointDetail from "./point-detail";
import SplitText from 'react-pose-text';
import {charPoses} from '../lib/animations';


class RegionDetail extends React.Component {
	constructor(props) {
		super();
	}

	state = {
		visible: false
	};

	componentDidMount() {
		const app = this;

		setTimeout(function(){
			app.setState({
				visible: true
			})
		},500);
	}

	render() {
		const title = this.props.activePoint > 0 && this.props.region.points[this.props.activePoint - 1] ? this.props.region.points[this.props.activePoint - 1].title : this.props.title;
		return (
			<div>
				{this.props.isActive && (
					<div
						index={this.props.index}
						className={
							"region-detail region-detail--is-active-" +
							this.props.isActive
						}
					>
						<h1>
							<SplitText charPoses={charPoses} pose={this.props.transition === 'in' ? 'enter' : 'exit'} >{title}</SplitText>
						</h1>
						{this.props.activePoint === 0 &&
								<PointIndex transition={this.props.transition} pageChangeStatus={this.props.pageChangeStatus} key="10" region={this.props.id} points={this.props.region.points} viewPoint={this.props.viewPoint} />
							}

							{this.props.activePoint > 0 &&
								<PointDetail toggleForm={this.props.toggleForm} transition={this.props.transition} key="20"point={this.props.region.points[this.props.activePoint - 1]} brochure={this.props.brochure} />							
							}
						
					</div>
				)}
			</div>
		);
	}
}

RegionDetail.propTypes = {
	isActive: PropTypes.bool,
	region: PropTypes.object.isRequired,
	activePoint: PropTypes.number.isRequired,
	viewPoint: PropTypes.func
};

export default RegionDetail;
