import React from "react";
import PropTypes from 'prop-types';
import Form from './Form';



class FormPopOut extends React.Component {
    constructor (props) {
        super();
    }

    componentDidMount() {}

    render() {

        const { form, file } = this.props;
        const title = file && file.title ? file.title : form.title;
        return (
            <div className={`form-pop-out ${this.props.open ? 'is-open' : 'is-closed'}`}>
                <div className="form-pop-out__content form-pop-out__content--after-open" tabIndex="-1" role="dialog">
                    <div className="form-pop-out__inner">
                        <Form formURL={this.props.formURL} {...form} title={title} inDrawer file={file} closeFormFunc={this.props.toggleForm}/>
                    </div>
                </div>
            </div>
        );
    }   
}

FormPopOut.propTypes = {
    open: PropTypes.bool
}
 

export default FormPopOut;