import PropTypes from 'prop-types'
import * as React from 'react'

const LoadingBar = ({ isFinished, progress, animationDuration }) => (
  <div
    className="loading-bar"
    style={{
      opacity: isFinished ? 0 : 1,
      marginLeft: `${(-1 + progress) * 100}%`,
      transition: `margin-left ${animationDuration}ms linear`,      
    }}
  >
    <div className="loading-bar-progress" />
  </div>
)

LoadingBar.propTypes = {
  animationDuration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired
}

export default LoadingBar
