// Vendor libs.
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// Custom components.
import Icon from './Icon';

/**
 * Custom Form Input Component.
 * Used to build out all form inputs for all forms.
 *
 * @author Charles Harwood
 * @category Components
 * @extends {Component}
 */
class Input extends Component {

	static propTypes = {
		children: PropTypes.node,
		type: PropTypes.string,
		disabled: PropTypes.bool,
		group: PropTypes.bool,
		validate: PropTypes.bool,
		size: PropTypes.string,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		onFocus: PropTypes.func,
		id: PropTypes.string,
		name: PropTypes.string,
		hint: PropTypes.string,
		value: PropTypes.string,
		checked: PropTypes.bool,
		default: PropTypes.string,
		error: PropTypes.string,
		success: PropTypes.string,
		label: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.object
		]),
		labelClass: PropTypes.string,
		choices: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		el: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		className: PropTypes.string,
		containerClass: PropTypes.string,
		filled: PropTypes.bool,
		gap: PropTypes.bool,
		getValue: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
	}

	static defaultProps = {
		tag: 'input',
		type: 'text',
		hint: null,
		getValue: false
	}

	/**
	 * Creates an instance of Input.
	 *
	 * @param {Input.propTypes} props The component's properties.
	 * @returns {Input} An instance of the component.
	 */
	constructor(props) {
		super(props);

		this.state = {
			innerValue: props.value || props.default || '',
			isTouched: false,
			isPristine: true
		};

		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.triggerFocus = this.triggerFocus.bind(this);
	}

	/**
	 * Invoked right before calling the render method,
	 * both on the initial mount and on subsequent updates.
	 *
	 * @static
	 * @param {{}} nextProps The value of `this.props` after the impending render.
	 * @param {{}} prevState The value of `this.state` before the impending render.
	 * @returns {{}|null} An object to update the state, or null to update nothing.
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.value === prevState.value) {
			return null;
		}

		return {
			...prevState,
			innerValue: nextProps.value
		};
	}

	componentDidMount() {
		if (this.props.default && !this.props.value) {
			const fn = this.props.onChange;
			const { id, name, default: value, type } = this.props;
			fn && fn({
				target: {
					id,
					name,
					value,
					type
				}
			})
		}
	}

	onBlur(event) {
		// ignore if event is a window blur
		if (document.activeElement !== this.inputElRef) {
			this.setState({ isTouched: false });
		}
		// execute callback
		const fn = this.props.onBlur;
		fn && fn(event);
	}

	onFocus(ev) {
		// ignore if event is a window blur
		// if (document.activeElement === this.inputElRef) {
		this.setState({ isTouched: true });
		// }
		// execute callback
		const fn = this.props.onFocus;
		fn && fn(ev);
	}

	onChange(ev) {
		if (this.props.type !== 'checkbox' && this.props.type !== 'radio') {
			this.setState({
				innerValue: ev.target.value,
				isPristine: false
			});
		}

		// execute callback
		const fn = this.props.onChange;
		fn && fn(ev);
		this.props.getValue && this.props.getValue(ev.target.value);
	}
	triggerFocus() {
		// hack to enable IE10 pointer-events shim
		this.inputElRef.focus();
	}

	getValueHandler() {
		return this.state.innerValue;
	}

	renderSelect(attributes, classes) {
		const { id, hint, choices } = this.props;

		return (
			<>
				<select
					{ ...attributes }
					id={ id }
					className={ classes }
					ref={ (el) => { // eslint-disable-line no-shadow
						this.inputElRef = el;
					} }
					value={ this.state.innerValue }
					placeholder={ hint }
					onBlur={ this.onBlur }
					onChange={ this.onChange }
					onFocus={ this.onFocus }
				>
					<option value=""></option>
					{ choices.map((choice, index) =>
						<option
							key={ index }
							value={ choice.value }
						>
							{ choice.text }
						</option>
					)}
				</select>
				<Icon colour="rgba(76,86,95,0.87)" name="downtriangle" />
			</>
		)
	}

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The component markup.
	 */
	render() { // eslint-disable-line complexity
		const {
			children,
			containerClass,
			size,
			group,
			getValue,
			className,
			type,
			el,
			tag,
			id,
			hint,
			validate,
			value,
			label,
			error,
			success,
			disabled,
			labelClass,
			filled,
			gap,
			choices,
			...attributes
		} = this.props;

		const isNotEmpty =
			Boolean(this.state.innerValue) || hint || this.state.isTouched;

		const textareaInput = type === 'textarea';
		const selectInput = type === 'select';
		const Tag = textareaInput || selectInput ? type : 'input';

		const formControlClass = 'form__input';

		if (Tag === 'input') {
			attributes.type = type;
		}

		if (disabled) {
			attributes.disabled = true;
		}

		const classes = classNames(
			formControlClass,
			validate ? 'validate' : false,
			filled ? 'filled-in' : false,
			gap ? 'with-gap' : false,
			type === 'checkbox' ? gap ? false : 'form__check-input' : false, // eslint-disable-line no-nested-ternary
			type === 'radio' ? 'form__check-input' : false,
			className
		);

		const containerClassFix = classNames(
			type === 'checkbox' || type === 'radio' ? 'form__check-wrapper' : 'form__field-wrapper',
			(type === 'checkbox' || type === 'radio') && this.props.checked ? 'checked' : false,
			group ? 'form__group' : false,
			containerClass
		);

		const labelClassFix = classNames(
			isNotEmpty ? 'active' : false,
			isNotEmpty && type === 'textarea' && 'active--extra-spacing',
			disabled ? 'disabled' : false,
			type === 'checkbox' ? 'form__check-label' : false,
			type === 'radio' ? 'form__check-label form__radio-label' : false,
			labelClass
		);

		return (
			<div className={ containerClassFix }>
				{ label
					? <label
						className={ labelClassFix }
						htmlFor={ id }
						data-error={ error }
						data-success={ success }
						onClick={ this.triggerFocus }
						dangerouslySetInnerHTML={{ __html: label }}
					/>
					: false
				}
				<div className="form__input-wrapper">
					{ selectInput
						? this.renderSelect(attributes, classes)
						: <Tag
							{ ...attributes }
							id={ id }
							className={ classes }
							ref={ (el) => { // eslint-disable-line no-shadow
								this.inputElRef = el;
							} }
							value={ this.state.innerValue }
							placeholder={ hint }
							onBlur={ this.onBlur }
							onChange={ this.onChange }
							onFocus={ this.onFocus }
						/>
					}
				</div>
				{ children }
			</div>
		);
	}

}

export default Input;
