import posed from "react-pose";

const AnimateParent = posed.div({
    open: {
      opacity: 1,
      delayChildren: 300,
      staggerChildren: 100
    },
    closed: { opacity: 0, delay: 300 }
  });
  
const AnimateChild = posed.article({
    open: { x: 0, opacity: 1 },
    closed: { x: 100, opacity: 0 }
});

const ExpandingNavItem = posed.li({
  open: { maxWidth: 200, flip: true },
  closed: { maxWidth: 0}
});

const Modal = posed.div({
	enter: {
		y: 0,
		opacity: 1,
		delay: 2000,
		transition: {
			y: { duration: 300 }
		}
	},
	exit: {
		y: 50,
		opacity: 0,
		transition: { duration: 150 }
	}
});

const PopInOut = posed.div({
	in: { 
        opacity: 1, 
        x: 0,
        delay: 2000, 
         },
    out: { 
        opacity: 0,
        x: 100 
    }	
});

const Container = posed.div({
	enter: { 
        opacity: 1, 
        delay: 300, 
        beforeChildren: 300,
        delayChildren: 2200,
		staggerChildren: 50 },
    exit: { opacity: 0 }	
});

const Pressable = posed.div({
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.9 }
  });

  const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 300, beforeChildren: 300 },
    exit: { opacity: 0 }
  });

  const charPoses = {
    exit: { opacity: 0 },
    enter: { opacity: 1, delay: ({ charIndex }) => charIndex * 50  }
    };

export {AnimateParent, AnimateChild, Modal, Container, Pressable, PopInOut, RouteContainer,ExpandingNavItem,charPoses }