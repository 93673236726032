import React from "react";
import PropTypes from 'prop-types';
import {AnimateParent, AnimateChild} from '../lib/animations';
import Slider from "react-slick";
import ScrollBox from "./scroll-box";

class PointDetail extends React.Component {
    constructor (props) {
        super();
    }

    state = {
        isOpen: false
    };

  

    componentDidMount() {

    }

    render() {
        const sliderSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };

        return (
            <div className="point-detail">
                <ScrollBox  transition={this.props.transition}>
                    <AnimateParent className="products" pose={this.props.transition === 'in' ? 'open' : 'closed'}>
                        {this.props.point.products.map((product, index) =>
                            <AnimateChild className="product-card" key={index}>
                                <div className={`icon icon-${product.icon}`}></div>
                                <h1>{product.title}</h1>
                                <p>{product.copy}</p>
                            </AnimateChild>                    
                        )}
                    </AnimateParent>       
                    
                    {this.props.point.image && Array.isArray(this.props.point.image) ?
                        <Slider {...sliderSettings} className="image-gallery">
                            {this.props.point.image.map((image, index) => 
                                <figure key={index}>
                                    <img  src={image.image} alt=""/> 
                                
                                    <figcaption>
                                        {image.title && 
                                            <h1>{image.title}</h1>
                                        }
                                        <p>
                                            {image.copy}
                                        </p>
                                    </figcaption>     
                                </figure>                             
                            )}
                        </Slider>
                        :
                            <figure>
                                <img src={this.props.point.image} alt=""/>
                                <figcaption>
                                    <p>
                                        {this.props.point.copy}
                                    </p>
                                </figcaption>    
                            </figure>
                        }

                    {this.props.brochure && (
                        <a href="#brochure" onClick={this.props.toggleForm} className={`brochure-link button`} >
                            <span className="top-bar"></span>
                            <span className="label">request BROCHURE</span>
                        </a>
                    )}
                </ScrollBox>
            </div>
        );
    }   
}

PointDetail.propTypes = {
    point: PropTypes.object
}
 

export default PointDetail;