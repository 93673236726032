import React from "react";
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';
import * as pulseAnimation from '../animations/dot-pulse.json';
import * as rapidAnimation from '../animations/dot-rapid.json';
import Zone1 from '../assets/images/map/01-zone.svg';
import Zone2 from '../assets/images/map/02-zone.svg';
import Zone3 from '../assets/images/map/03-zone.svg';
import Zone4 from '../assets/images/map/04-zone.svg';
import Zone5 from '../assets/images/map/05-zone.svg';
import Zone6 from '../assets/images/map/06-zone.svg';
import Zone7 from '../assets/images/map/07-zone.svg';

const ZoneSVGs = [Zone1, Zone2, Zone3, Zone4, Zone5, Zone6, Zone7];


class MapSection extends React.Component {
    constructor (props) {
        super();
    }

    state = {
    };

  

    componentDidMount() {

	}

    render() {
        const isHighlight = this.props.mode === "highlight";
        

          
        return (
            <div 
                index={this.props.index}
                style={{
                    transform: this.props.transform ? this.props.transform : 'none',
                    opacity: this.props.isActive ? 1 : 0.15,
                    left: this.props.left + 'em',
                    top: this.props.top + 'em'
                }} 
                className={
                    `map-section point-detail--${(this.props.viewPoint > 0)} region-id-${this.props.index} map-section--is-highlighted-${this.props.isHighlighted} map-section--is-active-${this.props.isActive}`
                }>
                    {!isHighlight && 
                        <>
                            <div className="label-position" data-region={this.props.index}></div>
                        </>
                    }

                    {isHighlight &&
                        <>
                            {this.props.region.points.map((point, index) => {
                                const defaultOptions = {
                                    loop: true,
                                    autoplay: true, 
                                    animationData: point.pin === "rapid" ? rapidAnimation.default : pulseAnimation.default,
                                    rendererSettings: {
                                      preserveAspectRatio: 'xMidYMid slice'
                                    }
                                };

                                const activePoint = this.props.region.points[this.props.viewPoint-1];                             
                                //Only one rapid point should show per region, but should be highlighted for all rapid points
                                const showPoint = this.props.viewPoint === (index+1) || (activePoint && activePoint.pin === "rapid" && point.pin === "rapid") ? true : false;

                                return ( 
                                    <div key={index} >
                                        {point.x && point.y &&
                                            <Link 
                                                key={index} 
                                                to={`/${this.props.index}/${index}`} 
                                                style={{
                                                    top: `${point.y}%`,
                                                    left: `${point.x}%`
                                                }}
                                                className={`point-dot point-dot-${this.props.index}-${index} point-dot-is-active-${showPoint}`}>
                                                <Lottie options={defaultOptions}
                                                    height={70}
                                                    width={70}
                                                    isStopped={!(this.props.isHighlighted || this.props.isActive)}
                                                    isPaused={!this.props.isHighlighted}/>
                                            </Link>
                                        }
                                    
                                    </div>)
                                }                               
                            )}
                        </>
                    }
                    
                    <SVG src={ZoneSVGs[this.props.index - 1]} width={this.props.region.width/16 + 'em'} />
            </div>
        );
    }   
}

MapSection.propTypes = {
    transform: PropTypes.string,
    isActive: PropTypes.bool,
    isHighlighted: PropTypes.bool
}
  

export default MapSection;