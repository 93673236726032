import React from "react";
import PropTypes from 'prop-types';
import MapSection from "./map-section";
import SVG from 'react-inlinesvg';
import BG from '../assets/images/map/bg-texture.svg';
import { ScreenSize } from "../lib/functions";


class Map extends React.Component {
    /** Position the map at different screen sized on different devices */
    mapViews = {
        "desktop" : {
            0: 'rotateX(-52deg) rotateZ(23deg) rotateY(10deg) translateY(-91%) scale(1)', // Overview
            1: "rotateX(0deg) rotateZ(90deg) translateY(-6vw) translateX(-64vh) scale(1)", // Check In
            2: "rotateX(0deg) rotateZ(0deg) translateY(-68vh) translateX(-18vw) scale(1)", // Bag Screening
            3: "rotateX(0deg) rotateZ(90deg) translateY(7vw) translateX(-62vh) scale(0.7)",   // Baggage Handling
            4: "rotateX(0deg) rotateZ(0deg) translateY(-36vh) translateX(-18vw) scale(1.4)", // Retail
            5: "rotateX(0deg) rotateZ(0deg) translateY(-40vh) translateX(-30vw) scale(0.8)", // Departure
            6: "rotateX(0deg) rotateZ(0deg) translateY(-40vh) translateX(-5vw) scale(0.8)", // Arrivals
            7: "rotateX(0deg) rotateZ(90deg) translateY(-6vw) translateX(-48vh) scale(1)" // Reclaim
        },
        "ipad-pro" : {
            0: 'rotateX(-52deg) rotateZ(23deg) rotateY(10deg) translateY(-89%) translateX(-20%) scale(1.5)', // Overview
            1: "rotateX(0deg) rotateZ(90deg) translateY(-6vw) translateX(-64vh) scale(1)", // Check In
            2: "rotateX(0deg) rotateZ(0deg) translateY(-68vh) translateX(-18vw) scale(1)", // Bag Screening
            3: "rotateX(0deg) rotateZ(90deg) translateY(7vw) translateX(-62vh) scale(0.7)",   // Baggage Handling
            4: "rotateX(0deg) rotateZ(0deg) translateY(-36vh) translateX(-18vw) scale(1.4)", // Retail
            5: "rotateX(0deg) rotateZ(0deg) translateY(-40vh) translateX(-30vw) scale(0.8)", // Departure
            6: "rotateX(0deg) rotateZ(0deg) translateY(-40vh) translateX(-5vw) scale(0.8)", // Arrivals
            7: "rotateX(0deg) rotateZ(90deg) translateY(-6vw) translateX(-48vh) scale(1)" // Reclaim
        },
        "ipad" : {
            0: 'rotateX(-52deg) rotateZ(23deg) rotateY(10deg) translateY(-85%) scale(0.8) translateX(-48vw)', // Overview
            1: "rotateX(0deg) rotateZ(90deg) translateY(-1.1vw) translateX(-92vh) scale(0.9)", // Check In
            2: "rotateX(0deg) rotateZ(0deg) translateY(-78vh) translateX(-36vw) scale(0.9)", // Bag Screening
            3: "rotateX(0deg) rotateZ(90deg) translateY(21vw) translateX(-69vh) scale(0.7)",  // Baggage Handling
            4: "rotateX(0deg) rotateZ(0deg) translateY(-36vh) translateX(-36vw) scale(1.4)", // Retail
            5: "rotateX(0deg) rotateZ(-76deg) translateY(-41vh) translateX(29vw) scale(0.6)", // Departure
            6: "rotateX(0deg) rotateZ(76deg) translateY(53vh) translateX(-42vw) scale(0.8)", // Arrivals
            7: "rotateX(0deg) rotateZ(90deg) translateY(4vw) translateX(-68vh) scale(0.8)" // Reclaim
        }
    }

    

    state = {
        viewRegion: this.props.viewRegion
    }
 
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        const map = this;
        
        if(prevProps.viewRegion !== this.props.viewRegion){
            
            if(this.props.toggleAnimation) {
                this.props.toggleAnimation();

                setTimeout(function(){
                    map.props.toggleAnimation();
                },2000)
            }

           
        }

    }

    render() {

        let transform = this.mapViews[ScreenSize()][this.props.viewRegion];

        if (this.props.mode === "highlight" && this.props.highlightRegion !== 0) {
            transform += " translateZ(-25px)"
        }

        return (
            <div 
            className={`map map--default-view map--default-view--${this.props.viewRegion === 0} map--mode--${this.props.mode}`}
            ref={ (mapElement) => this.mapElement = mapElement}
            style={{
                transform: transform
            }}  >
                {this.props.regions.map((region, index) =>
                    <MapSection 
                        index= {index+1}
                        region= {region}
                        key={index}
                        isActive={this.props.viewRegion === 0 || this.props.viewRegion === (index+1) ? true : false} 
                        isHighlighted={this.props.highlightRegion === (index+1) ? true : false}                         
                        mode={this.props.mode}
                        top={region.top}
                        left={region.left}
                        transform={region.transform}
                        viewPoint={this.props.viewPoint}
                    />
                    
                )}
                {this.props.mode !== "highlight" && 
                    <SVG src={BG} width={883.91/16 + 'em'}/>
                }
                
            </div>
        );
    }   
}

Map.propTypes = {
    viewRegion: PropTypes.number,
    viewPoint: PropTypes.number,
    highlightRegion: PropTypes.number,
    mode: PropTypes.string,
    regions: PropTypes.array.isRequired
}

export default Map;