import React from "react";
// import Client from "../Client";
import { NProgress } from '@tanem/react-nprogress'
import LoadingBar from './loading-bar';
import Map from './map';
import Navigation from './navigation';
import RegionDetail from './region-detail';
import Data from '../content/data';
import Header from './header';
import Overlay from './overlay';
import FormPopOut from './form-popout';
import { ScreenSize } from "../lib/functions";


class LandingPage extends React.Component {
    
    

    constructor () {
        super();
        this.viewPoint = this.viewPoint.bind(this);
        this.countClick = this.countClick.bind(this);
        this.toggleAnimation = this.toggleAnimation.bind(this);
        this.pageChangeStatus = this.pageChangeStatus.bind(this);
        this.toggleForm = this.toggleForm.bind(this);

        this.autoPlayInterval = null;
        const page = this;

        this.autoPlayTimeout = 5000;
        this.autoPlaySpeed = 10000;


        // Set timer to start the autoplay mode 
        this.clickTimer = setTimeout(function(){
            page.startAutoPlayMode();
        },this.autoPlayTimeout);

    }

    state = {
        title: Data.title,
        subtitle: Data.subtitle,
        isLoading: false,
        viewRegion: this.props && this.props.match.params.region ? this.props.match.params.region : 0,
        autoPlay: false,
        highlightRegion: 0,
        activePoint: 0,
        regionLabelPositions: [],
        animating: false,
        transition: "in",
        popOutOpen: false,
    };

    componentDidMount() {
        const app = this;

        // document.documentElement.requestFullscreen();
        if (document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
            console.log(`Error attempting to enable full-screen mode`);
          });
        }

        // Wait for the map to be positioned before working out the positions of the labels
        setTimeout(function(){
            app.getMapPositions();
        },2000);

        
        
    }
    
    // Use the position of the .label-position divs to determine the position of the labels, that are not transformed and rotated.
    getMapPositions() {
        const dots = document.getElementsByClassName('label-position');
        let objectMap = [];

        //For each Label, get x & y and set to state
        Array.prototype.forEach.call(dots, (element) => {
            objectMap[element.dataset.region] = {
                x: element.getBoundingClientRect().x,
                y: element.getBoundingClientRect().y
            };
        });

        this.setState({
            regionLabelPositions: objectMap
        })

    }

    // Clear autoplay timers and change state
    stopAutoPlayMode() {
        window.clearTimeout(this.clickTimer);
        window.clearInterval(this.autoPlayInterval);

        this.clickTimer = null;

        this.setState({
            highlightRegion: 0,
            autoPlay: false
        })
    }

    //Start Autoplay, create Interval to move the highlighted section around the map
    startAutoPlayMode() {
        const page = this;

        if(!this.state.autoPlay && !this.props.match.params.region){

            this.setState({
                 autoPlay: true
            });

            this.autoPlayInterval = setInterval(() => {
                let region = this.state.highlightRegion + 1;

                if(region > Data.regions.length) {
                    region = 1
                }

                this.setState({
                    highlightRegion: 0
                });

                setTimeout(() => {
                    page.setState({
                        highlightRegion: region
                    })
                }, 2000);
            }, this.autoPlaySpeed);
        }
    }

    //If a click has been logged, reset the autoplay timer
    countClick(e) {
        const page = this;

        this.stopAutoPlayMode();

        if(!this.props.match.params.region) {

            this.clickTimer = setTimeout(function(){
                page.setState({
                    autoPlay: true
                })
            },this.autoPlayTimeout);    
        } 
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const app = this;
        
        //If the map has gone back to default view from a detail view, recheck the label positions
        if(prevProps.match.params.region && !this.props.match.params.region) {
            setTimeout(function(){
                app.getMapPositions();
            },2000);
            setTimeout(function(){
                app.getMapPositions();
            },3000);
        }

        //If the map is on default view start the autoplay timer
        if(!this.props.match.params.region && !this.state.autoPlay) {
            this.startAutoPlayMode();
        }

        //If the map is on a detail view stop the autoplay
        if(this.props.match.params.region && this.state.autoPlay){
            this.stopAutoPlayMode();
        }
    }


    // Set state to check if the map is animating
    toggleAnimation(){
        this.setState({
            animating: !this.state.animating
        })
    }

    // Set Detail point
    viewPoint(point) {
        
        this.setState({
            activePoint: point
        })
    }

    // Set state to check if detail page content is changing
    pageChangeStatus(status) {
        this.setState({
            transition: status
        })
    }

    //Popout form for brochure download
    toggleForm() {
        this.setState({
            popOutOpen: !this.state.popOutOpen
        })
    }

    render() {
        //If detail view, what region and what point are being viewed. 0 = index.
        const regionID = this.props.match.params.region ? parseInt(this.props.match.params.region) : 0; 
        const pointID = this.props.match.params.point ? parseInt(this.props.match.params.point) : 0; 
        const regionArrNum = this.props.match.params.region ? parseInt(this.props.match.params.region - 1) : 0;
        const pointArrNum = this.props.match.params.point ? parseInt(this.props.match.params.point -1) : 0;

        return (
            // NProgress so loading animation can be used if needed
            <NProgress isAnimating={this.state.isLoading}>
                {({ isFinished, progress, animationDuration }) => (
                    <div id="landing-page" className={`${this.state.autoPlay ? "auto-play-mode" : ''} transition-${this.state.transition} screen-${ScreenSize()}` } onClick={this.countClick}>
                        {/* NProgress loading bar */}
                        <LoadingBar isFinished={isFinished} progress={progress} animationDuration={animationDuration} />
                        {/* Title and Home link */}
                        <Header title={this.state.title} subtitle={this.state.subtitle} viewRegion={regionID}  pageChangeStatus={this.pageChangeStatus} regions={Data.regions} displayTitles={regionID === 0} />
                        {/* Labels and click zones for regions */}
                        <Overlay 
                            regions={Data.regions} 
                            viewRegion={regionID}  
                            viewPoint={pointID}
                            highlightRegion={this.state.highlightRegion}
                            labelPositions={this.state.regionLabelPositions} />
                        {/* Highlighted section of the map, only visible when a region is highlighted */}
                        <Map 
                            regions={Data.regions}  
                            mode="highlight" 
                            highlightRegion={this.state.highlightRegion} 
                            viewRegion={regionID}
                            viewPoint={pointID} />
                        {/* Main Map */}
                        <Map regions={Data.regions} viewRegion={regionID} viewPoint={pointID} toggleAnimation={this.toggleAnimation} />

                        {/* Content -> Region Detail -> Point Detail */}
                        <div className={`content ${regionID === 0 ? "default-view" : ""} ${this.state.animating ? "is-animating" : "animation-complete"}`}>
                            {regionID > 0 && Data.regions[regionID-1] ? <RegionDetail 
                                    key="1"
                                    title={Data.regions[regionID-1].title}
                                    id={regionID}
                                    region={Data.regions[regionID-1]}
                                    viewPoint={this.viewPoint}
                                    pageChangeStatus={this.pageChangeStatus}
                                    activePoint={pointID}
                                    transition={this.state.transition}
                                    isActive={true} 
                                    toggleForm={this.toggleForm} 
                                    brochure={Data.regions[regionArrNum].points[pointArrNum].brochure ? true : false}/>
                                : null}                            
                        </div>
                        
                        {/* Brochure Download */}
                        <FormPopOut open={this.state.popOutOpen} toggleForm={this.toggleForm} formURL={Data.formSiteUrl} form={Data.register_form} file={Data.regions[regionArrNum].points[pointArrNum].brochure}/> 

                        {/* Navigation */}
                        <Navigation highlightRegion={this.state.highlightRegion}  pageChangeStatus={this.pageChangeStatus} regions={Data.regions}  viewRegion={regionID} viewPoint={pointID} />

                        {/* Presentational ELements */}
                        <div className="background bg-1">

                        </div>
                        <div className="background bg-2">
                            
                        </div>
                    </div>
                )}
            </NProgress>
        );
    }   
}

export default LandingPage;