import React from "react";
import PropTypes from "prop-types";
import posed from "react-pose";
// import { AnimateParent, AnimateChild } from "../lib/animations";

const Draggable = posed.div({
	hoverable: true,
	draggable: 'y',
	dragBounds: (props) => ({ top: props.top, bottom: props.bottom }) ,
	init: { scale: 1 },
	hover: { scale: 1.2 },
	drag: { scale: 1.1 },
	passive: {
		y: ['x', v => v]
	  }
  })


class ScrollBox extends React.Component {
    

    constructor(props) {
		super();
		this.handleScroll = this.handleScroll.bind(this);
		this.moveScrollBar = this.moveScrollBar.bind(this);
	}

	state = {
		isOpen: false,
		scrollTrackHeight: 0,
		scrollBoxHeight: 0,
		scrollPosition: 0
	};

	componentDidMount() {
		const point = this;

		let height = this.scrollBox.clientHeight;
		this.setState({ height });
	
		setTimeout(function(){
			if(point.scrollTrack){
				height = point.scrollTrack.clientHeight;

				point.setState({
					isOpen: true,
					scrollTrackHeight: height
				});
			}
			
		},2000);

	}
    
    handleScroll(e) {
		let element = e.target;
		const position = element.scrollTop / (element.scrollHeight - element.clientHeight);

		this.setState({
			scrollPosition: position
		});

	}

	moveScrollBar(y){
		if(this.scrollBox){
			const height = this.scrollTrack.clientHeight;

			const position = y / height;
			const yPosition = (this.scrollBox.scrollHeight - this.scrollBox.clientHeight) * position;

			this.scrollBox.scrollTo(0, yPosition);

			this.setState({
				scrollPosition: position,
				scrollTrackHeight: height
			});
		}
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.transition === 'out' && this.props.transition === 'in'){
            this.scrollBox.scrollTo(0, 0);
            this.setState({
                scrollPosition: 0
            });
        }
    }
	 

    render() {

        return (
            <>
                <div className="scroll-bar-container"
					style={{
						display: this.scrollBox && (this.scrollBox.scrollHeight - 56) <= this.scrollBox.clientHeight ? "none" : "block"
					}}>
					<div className="scroll-bar-track" 
						ref={ (scrollTrack) => this.scrollTrack = scrollTrack}
					>
						<Draggable 
							className="scroll-bar" 
							bottom={this.state.scrollTrackHeight} 
							top={0} 
							onValueChange={{ y: y => this.moveScrollBar(y) }}
							style={{
								transform: `translateY(${this.state.scrollPosition * this.state.scrollTrackHeight}px) scale(1) translateZ(0px)`
							}}></Draggable>
					</div>
				</div>
				
				<div style={{
                    overflowY: this.scrollBox && this.scrollBox.scrollHeight === this.scrollBox.clientHeight ? "visible" : "auto"
                }} className="scroll-box"  onScroll={this.handleScroll} ref={ (scrollBox) => this.scrollBox = scrollBox} >
                    {this.props.children}
				</div>
            	
            </>
        );
    }   
}

ScrollBox.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
 

export default ScrollBox;