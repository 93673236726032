import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Btn from "./btn";



class Overlay extends React.Component {
    constructor (props) {
        super();
    }

    state = {
    };

  

    componentDidMount() {

    }


    render() {
        const overlay = this;

        return (
            <div 
                className={`overlay overlay--default-view--${this.props.viewRegion === 0} overlay--mode--${this.props.mode}`}>
                {this.props.regions.map((region, index) => {
                    const id = index + 1;
                    const isHighlighted = overlay.props.highlightRegion === id;
                    const isActive = overlay.props.viewRegion === id;

                   
                    const labelTop = overlay.props.labelPositions && overlay.props.labelPositions[id] ? overlay.props.labelPositions[id].y : 0;
                    const labelLeft = overlay.props.labelPositions && overlay.props.labelPositions[id] ? overlay.props.labelPositions[id].x : 0;
                    return (<div 
                        key={id}
                        className={`region-overlay region-overlay-${id} region-overlay--is-highlighted-${isHighlighted} region-overlay--is-active-${isActive}`
                        }>
                        <Link to={`/${id}`} className="region-click-zone">
                            
                        </Link>
                        <Btn 
                            to={`/${id}`} 
                            className={`region-label point positioned-${!!(overlay.props.labelPositions && overlay.props.labelPositions[id])}`} 
                            style={{
                                    top: labelTop,
                                    left: labelLeft
                                }}
                            delay={0}>                   
                                {region.title}
                        </Btn>
                    </div>)
                })}
            </div>
        );
    }   
}

Overlay.propTypes = {
    viewRegion: PropTypes.number,
    highlightRegion: PropTypes.number,
    mode: PropTypes.string,
    regions: PropTypes.array.isRequired,
    labelPositions: PropTypes.array
}

export default Overlay;