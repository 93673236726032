import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import AnimatedLink from "./animated-link";



class Header extends React.Component {
    constructor (props) {
        super();
    }

    state = {
    };

  

    componentDidMount() {

    }

    regionLink(id, region, url, points, includePoints) {
        return (
            <li
                key={id}
                className={`navigation-region region-${id} ${this.props.viewRegion === id ? 'is-active' : ''} ${(this.props.viewRegion === id && this.props.viewPoint === 0) || this.props.highlightRegion === id ? 'is-highlighted' : ''}`}
                >
                {/* // eslint-disable-next-line */}
                <AnimatedLink region={id} point={id}  clickEvent={this.clickEvent} delay={this.props.viewRegion === 0 ? 0 : 500} pageChangeStatus={this.props.pageChangeStatus} to={url}>{region}</AnimatedLink>
            </li>
        )
    }


    render() {
        return (
            <div className="header">
                <Link to="/"><h1 className="daifuku-logo">Daifuku Airport Technologies</h1></Link>
                {this.props.displayTitles && 
                    <>
                        {this.props.title &&
                            <h2>{this.props.title}</h2>
                        }
                        {this.props.subtitle &&
                            <h3>{this.props.subtitle}</h3>
                        } 
                    </>
                }

                

                <ul className={"dots"} style={{
                    opacity: this.props.viewRegion !== 0 ? 1 : 0
                }}>         
                    {this.props.viewRegion !== 0 && <Link to="/">Home</Link>}           
                    {this.props.regions.map((region, index) => this.regionLink(index+1, region.title, `/${index+1}`, region.points, false))}
                </ul>   
                             
            </div>
        );
    }   
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
}
 

export default Header;