import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
    render() {
        return (
			<article className='component rich-text text--left boxed privacy-policy'>
				<h2 className='body--3 '>
					Daifuku Group Information Protection Policy
				</h2>
				<p className='body--3 '>
					The protection of personal information (hereinafter referred
					to as “information security”) is a social responsibility
					that Daifuku Co., Ltd. and its group companies (referred to
					below as “Daifuku”) detail in the Basic Policy on
					Information Security with the aim of ensuring the maximum
					security.
				</p>
				<p className='body--3 '>Basic Policy on Information Security</p>
				<p className='body--3 '>
					Daifuku respects the privacy of its website visitors and
					implements the following policy to protect personal
					information.
				</p>
				<ol className='body--3 '>
					<li data-gc-list-depth='1' data-gc-list-style='ordered'>
						Daifuku will inform you of the purpose of use, as well
						as whom to contact with any related inquiries. You will
						only be asked to provide your personal information
						within the scope appropriate to the purpose. Daifuku
						will make use of personal information only within the
						scope appropriate to the purpose of use to which you
						have agreed. Daifuku will not supply or disclose your
						personal information to third parties except in cases
						where you have agreed beforehand.
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='ordered'>
						Daifuku will observe laws, regulations, norms and
						contracts relating to the personal information, secure
						the safe storage of personal information data, and
						implement suitable protective and corrective actions
						against illegal access to, missing, destruction,
						falsification and leakage of such.
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='ordered'>
						If you wish to review, correct, update or delete your
						personal information, please contact us. Daifuku will
						make the efforts to appropriately respond to your
						request.
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='ordered'>
						Daifuku will make the effort to improve the appropriate
						protection control system for personal information to
						meet your needs. Daifuku will observe its compliance
						program relating to personal information protection and
						improve it continuously.
					</li>
				</ol>
				<p className='body--3 text--right'>March 2005</p>
				<h3 className='body--3 "heading--4”'>
					1. Purpose of personal information collection
				</h3>
				<p className='body--3'>
					Daifuku will collect personal information, for the purpose
					of improving customer satisfaction by providing a better
					user experience, including customising products, information
					and services. We may ask for information such as name,
					e-mail address, company name, company address, company phone
					number, etc. for:
				</p>
				<ul className='body--3'>
					<li data-gc-list-depth='1' data-gc-list-style='bullet'>
						Application for seminars, events, and campaigns
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='bullet'>
						Answer to online questionnaire
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='bullet'>
						Request for brochures or online information
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='bullet'>
						Identification on request and inquiry
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='bullet'>
						Other information for better communication between you
						and Daifuku
					</li>
				</ul>
				<p className='body--3'>
					For purposes not listed above, Daifuku will clearly inform
					you of the purpose.
				</p>
				<h3 className='body--3 "heading--4”'>
					2. Use and supply of personal information
				</h3>
				<p className='body--3'>
					Daifuku will use your personal information and supply the
					information back to you within the scope of the following:
				</p>
				<ol className='body--3'>
					<li>
						Daifuku, in principle, will not collect personal
						information from you without showing the service
						contents and objectives, or without permission.
					</li>
					<li data-gc-list-depth='1' data-gc-list-style='ordered'>
						Daifuku may communicate with you through the media such
						as e-mail, mail, fax, or phone, using your personal
						information. You can contact us anytime to reject the
						following services:
						<ul>
							<li>
								Providing new product release information,
								technical information, and any other services
							</li>
							<li>
								Sending our brochures, magazines and other
								documents
							</li>
							<li>
								Providing invitations to an exhibition, seminar,
								campaign, online questionnaire and other events
							</li>
							<li>
								Showing necessary information on the product you
								purchased or the services you signed up for
							</li>
						</ul>
					</li>
					<li>
						Daifuku will make the efforts to appropriately respond
						to your request to review, confirm, correct or delete
						your personal information, aiming to update it. Please
						contact us.
					</li>
				</ol>
				<h3 className='body--3 "heading--4”'>
					3. Disclosure to third parties
				</h3>
				<p className='body--3'>
					Daifuku will never disclose the customer’s personal
					information except the following cases of:
				</p>
				<ol className='body--3'>
					<li>Obtaining the customer’s consent</li>
					<li>
						Disclosing to the third parties (which sign
						confidentiality agreements with Daifuku) to the
						necessary extent, to provide our services and
						information to the customer
					</li>
					<li>
						Sharing appropriate information with our affiliate
						companies and agents responding or following up the
						customer
					</li>
					<li>
						Receiving request to disclose according to laws and
						regulations
					</li>
				</ol>
				<h3 className='body--3 "heading--4”'>
					4. Fair management of personal information
				</h3>
				<p className='body--3'>
					To protect your personal information, Daifuku follows the
					guidelines below:
				</p>
				<ol className='body--3'>
					<li>
						Daifuku implements strict security measures to prevent
						missing, misuse or alternation of personal information
						under its management.
					</li>
					<li>
						Daifuku stores the registered personal information in
						safe circumstances protected by firewall where
						un-authorized people cannot access.
					</li>
				</ol>
				<h3 className='body--3 "heading--4”'>5. Use of cookies</h3>
				<p className='body--3'>
					Our website occasionally uses cookies to enhance the
					information and services provided and to make navigating the
					site more convenient. Cookies are used to identify you when
					you revisit the website at a later time. Cookies do not
					violate your privacy. You may set your Web browser to inform
					you before accepting new cookies or to refuse all cookies.
				</p>
				<p className='body--3'>
					You may decline to provide personal information to us and/or
					refuse cookies in your browser, although some of our
					features or services may not function properly as a result.
				</p>
				<h2 className='body--3' id='security'>
					Information Security Policy
				</h2>
				<p className='body--3'>
					This overarching policy provides top-level policies for
					information security in order to ensure that Daifuku and its
					affiliate companies (the Daifuku Group) protect their
					information assets.
				</p>
				<p className='body--3'>
					The Information Security Regulations provides details for
					the following information security controls.
				</p>
				<ol className='body--3'>
					<li>
						Information Security Management Organization
						<br />
						In order to carry out information security, an
						organisation should be implemented to promote the
						information security management. The organisation should
						also provide and develop the information security
						promotion plan for the Daifuku Group.
					</li>
					<li>
						Information Asset Management
						<br />
						All information assets that should be protected will be
						managed appropriately and classified according to
						priority and the information’s storage medium.
					</li>
					<li>
						Information System Management
						<br />
						In order to ensure that the information system maintains
						accuracy and security, the process for information
						system management should include the following:
						<br />
						(1) Network management
						<br />
						(2) System management
						<br />
						(3) Data management
						<br />
						(4) Vulnerability management
						<br />
						(5) Change management
						<br />
						(6) Log management
					</li>
					<li>
						Physical Environment Management
						<br />
						In order to prevent the information assets and the
						information system from loss, obstruction, or
						unauthorized physical access, all fields of information
						assets of the Daifuku Group should be controlled
						securely by providing necessary security controls such
						as physical protection, safeguards against criminals and
						fire, and access control.
					</li>
					<li>
						Human Resource Management
						<br />
						During the course of employment, the Daifuku Group is
						committed to providing information security
						instructions, agreements, information system guidance,
						training and enlightenments for executives, employees,
						temporary workers and all people who have access to the
						Company’s information assets to ensure that they
						understand and fulfil their specific information
						security responsibilities.
					</li>
					<li>
						Outsourcing Management
						<br />
						In the case of outsourcing related to information asset
						handling, a Non-Disclosure Agreement, which defines and
						controls the outsourcer’s responsibility, should be
						provided to ensure information asset protection.
					</li>
					<li>
						Compliance Management
						<br />
						In order to avoid any breach of information security
						regulations, contractual obligations, or security
						requirements, this policy and the Information Security
						Regulations should follow each country’s laws. The local
						information asset operation and local information
						security policy based on each country’s or regional
						rules should also follow these laws.
					</li>
					<li>
						Incident Management
						<br />
						Incident management should be carried out consistently
						and effectively, including all security incident
						reports, such as information leaks by virus infections,
						in order to ensure that the Daifuku Group is committed
						to build incident management plan and structure.
					</li>
					<li>
						BCP (Business Continuity Plan) Management
						<br />
						Regardless of any risk of threat, the Daifuku Group
						should cope with the suspension of business activities
						caused by the information system’s critical failure. In
						order to ensure important business processes are secured
						and continued without delay, the Daifuku Group is
						committed to building and maintaining the BCP, BCP
						training, and a BCP report framework.
					</li>
					<li>
						Assessment and Audit
						<br />
						In order to maintain and improve information security
						management, the assessment of effectiveness or
						activities for promoting information security measures
						should be implemented. An assessment or audit should be
						repeated periodically.
					</li>
				</ol>
				<p className='body--3 text--right'>
					Enactment date: April 1, 2005
					<br />
					Revision date: April 1, 2016
				</p>
			</article>
		);
    }
}

