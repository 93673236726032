// Vendor libs.
const SumoLogger = require('sumo-logger');

// Required options.
const opts = {
	endpoint: 'https://collectors.au.sumologic.com/receiver/v1/http/ZaVnC4dhaV3cAzMzYH26I4X_y3l4qo_Wfdycjxvs1l10GlS2AQEYrTwJzpiYigsU7cW8kiuw9_5mqMb7TGB5dVYpu8rhEFHdtAF4HMVu343MPMZj2tUcXw==',
	interval: 5000,
	sendErrors: true,
	sourceName: 'Daifuku ATec Frontend',
	sourceCategory: 'prod/web/client'
};

// Initialise SumoLogger.
const sumoLoggerInstance = new SumoLogger(opts);

/**
 * Reusable function to log errors to Sumo Logic.
 *
 * @author Charles Harwood
 * @category Utilities
 * @param {String | {}} _message Message or data object to send to Sumo Logic.
 * @param {String} _path The page URL or API endpoint where the error occurred.
 * @returns {void}
 */
const Logger = (_message, _path) => {
	let message = {};
	const sumoData = {
		url: _path
	};

	// If passed _message is an object, this is what we'll send.
	// If not, we likely just have a string so we pop it into an enclosing object.
	if (typeof _message === 'object') {
		message = _message;
	} else {
		message.message = _message;
	}

	// Log the message to Sumo Logic.
	sumoLoggerInstance.log(message, sumoData);
}

module.exports = Logger;
