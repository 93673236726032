/**!
 * @fileoverview Helper functions used across the client and server.
 *
 * @author Charles Harwood
 * @package Daifuku_ATec
 */

const zenscroll = require('zenscroll');

/**
 * Extract the value of a specific parameter in a URL.
 *
 * @category Utilities
 * @param  {String} url  The URL string to search within.
 * @param  {String} name The name of the URL param to find.
 * @return {String} The value of the URL param.
 */
const getUrlParameter = (url, name) => {
	const paramName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`, 'i');
	const results = regex.exec(url);
	return results === null
		? false
		: decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Cross-browser vanilla-js smooth scrolling function.
 *
 * @category Utilities
 * @param {HTMLElement} target The target element to scroll to.
 * @param {Number} duration The desired duration of the scroll animation
 * @param {Function} callback A callback function to fire once scrolling completes.
 * @returns {void}
 */
const smoothScroll = (target, duration, callback) => zenscroll.to(target, duration, callback)

/**
 * Parse and manipulate special characters in a plain-text string.
 *
 * @param {String} string A text or HTML string to perform replacements on before parsing to HTML.
 * @returns {React.DetailedReactHTMLElement|Array<React.DetailedReactHTMLElement>|String}
 * ReactElement or Array of ReactElements on successful parse, or string when `string` cannot be parsed as HTML.
 */
const parseSpecialCharacters = (string) => {
	const Parser = require('html-react-parser');
	const replacedString = string.replace(/®/g, '<sup>®</sup>');
	return Parser(replacedString);
}

/**
 * Extract the `slug` from a URL or Permalink.
 *
 * @category Utilities
 * @param {String} url The full URL or Permalink to extract from.
 * @returns {String} The extracted `slug`.
 */
const getSlug = (url) => {
	if (!url) {
		return null;
	}

	const parts = url.split('/');

	return parts.length > 2 ? parts[parts.length - 2] : '';
}

module.exports = {
	getUrlParameter,
	smoothScroll,
	parseSpecialCharacters,
	getSlug,
	
}
