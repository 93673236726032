import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

class Btn extends React.Component {
    constructor (props) {
		super();
		this.btnClick = this.btnClick.bind(this);
    }

    state = {
		animate: false
    };

  

    componentDidMount() {

	}
	

	btnClick() {
		const btn = this;
		btn.setState({
			animate: true
		})
		setTimeout(function(){
			btn.props.history.push(btn.props.to);
		},btn.props.delay);
		
	}
  

    render() {

        return (
            <button className={`btn ${this.props.className} ${this.state.animate ? "btn-clicked" : ""}`} style={{...this.props.style}} onClick={this.btnClick}>
                {/* <span className="top-bar"></span> */}
                <span className="label">{this.props.children}</span>				
            </button>
        );
    }   
}

Btn.propTypes = {
    to: PropTypes.string,
    delay: PropTypes.number
}
 

export default withRouter(Btn);