import React from "react";
import PropTypes from 'prop-types';
// import posed from 'react-pose';
import AnimatedLink from "./animated-link";

class Navigation extends React.Component {
    constructor (props) {
        super();
        this.clickEvent = this.clickEvent.bind(this);
    }

    state = {
        listClass: "",
        regionClasses: [

        ]
    };

  

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        const nav = this;
        
        if (prevProps.viewRegion !== this.props.viewRegion || this.state.listClass === "") {

           

            let newClass = "";
            let nextClass = "";

            if(this.props.viewRegion === 0) {
                newClass = "animate-to-home";
                nextClass = "animate-to-home show-home";
            } else if(prevProps.viewRegion !== 0) {
                newClass = "animate-from-detail animate-to-detail";
                nextClass = "animate-to-detail show-children";
            } else {
                newClass = "animate-to-detail";
                nextClass = "animate-to-detail show-children";
            }

            this.setState({
                listClass: newClass
            });

            setTimeout(function(){
                nav.setState({
                    listClass: nextClass
                });
            }, 2000);

        }
        
    }
    // eslint-disable-next-line 
    regionLink(id, region, url, points, includePoints) {
        return (
            <li
                key={id}
                className={`navigation-region region-${id} ${this.props.viewRegion === id ? 'is-active' : ''} ${(this.props.viewRegion === id && this.props.viewPoint === 0) || this.props.highlightRegion === id ? 'is-highlighted' : ''}`}
                >
                {/* // eslint-disable-next-line */}
                <AnimatedLink region={id} point={id}  clickEvent={this.clickEvent} delay={this.props.viewRegion === 0 ? 0 : 500} pageChangeStatus={this.props.pageChangeStatus} to={url}>{region}</AnimatedLink>
                {points && includePoints !== false &&
                    <ul>
                        {points.map((point, index) => this.pointLink(index+1, point.title, `/${id}/${index+1}`))}
                    </ul>
                }
            </li>
        )
    }

    pointLink(id, point, url) {
        return (
            <li
                key={id}
                className={`navigation-point point-${id} ${this.props.viewPoint === id ? 'is-active' : ''} ${this.props.viewPoint === id ? 'is-highlighted' : ''}`}
                >
                {/* // eslint-disable-next-line */}
                <AnimatedLink  point={id} clickEvent={this.clickEvent} pageChangeStatus={this.props.pageChangeStatus} delay={500} to={url}>{point}</AnimatedLink>
            </li>
        )
    }

    clickEvent(e) {

    }

    render() {

        return (
            <div className={`navigation ${this.state.listClass} navigation-state-view-${this.props.viewRegion === 0 ? 'default' : 'detail' }`}>
                 <AnimatedLink region={0} point={0} clickEvent={this.clickEvent} delay={0} to="/" className={this.props.viewRegion === 0 ? "is-active" : ""}>Home</AnimatedLink>
                <ul className={"main"}>                    
                    {this.props.regions.map((region, index) => this.regionLink(index+1, region.title, `/${index+1}`, region.points))}
                </ul>

                               
            </div>
        );
    }   
}

Navigation.propTypes = {
    viewRegion: PropTypes.number,
    viewPoint: PropTypes.number,
    regions: PropTypes.array.isRequired,
    pageChangeStatus: PropTypes.func,
    highlightRegion: PropTypes.number
}
 

export default Navigation;