import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

class AnimatedLink extends React.Component {
    

    state = {
		animate: false,
		delay: 1000
    };

	constructor (props) {
		super();
		this.btnClick = this.btnClick.bind(this);

		
    }

    componentDidMount() {
		
		this.setState({
			delay: this.props.delay || this.props.delay === 0 ? this.props.delay : 1000
		});
	}
	

	btnClick(e) {
		const btn = this;
		btn.setState({
			animate: true
		});

		if(btn.props.pageChangeStatus){
			btn.props.pageChangeStatus("out");
		}

		if(btn.props.clickEvent){
			btn.props.clickEvent(e);
		}
		
		setTimeout(function(){
			btn.props.history.push(btn.props.to);
			if(btn.props.pageChangeStatus){
				btn.props.pageChangeStatus("in");
			}
		},this.state.delay);
		
	}
  

    render() {

        return (
            <button data-region={this.props.region} data-point={this.props.point} className={`btn ${this.props.className} ${this.state.animate ? "btn-clicked" : ""}`} onClick={this.btnClick}>
				{this.props.children}
            </button>
        );
    }   
}

AnimatedLink.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}
 

export default withRouter(AnimatedLink);