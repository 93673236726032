// Vendor libs.
import React, { Component } from 'react';
import Axios from 'axios';
import classNames from 'classnames';
// import Analytics from 'react-ga';
import PropTypes from 'prop-types';
// Custom components.
import Input from './Input';
import RichText from './RichText';
import Privacy from './PrivacyPolicy';
// Custom utilities.
import { GeoContext } from '../utils/Context';
import { getUrlParameter, parseSpecialCharacters, smoothScroll } from '../utils/Helpers';
import Logger from '../utils/Logger';
import { wpImagePropTypes } from '../utils/types.spec';

//External Components
import Modal from 'react-responsive-modal';

// Google ReCAPTCHA.
import ReCAPTCHA from 'react-google-recaptcha';
const siteKey = '6LeJxpoUAAAAAPdsUb1eR7sauCF-EgSv1unZegLd';


/**
 * Component #7 — Contact Form.
 * Allows users enter personal information and get in touch without exposing an email address.
 *
 * @author Charles Harwood
 * @category Components
 * @extends {Component}
 */
class Form extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        button: PropTypes.object,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                label: PropTypes.string.isRequired,
                labelPlacement: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                cssClass: PropTypes.string.isRequired,
                choices: PropTypes.oneOfType([
                    PropTypes.array,
                    PropTypes.string
                ]),
                isRequired: PropTypes.bool,
                errorMessage: PropTypes.string
            })
        ),
        id: PropTypes.number.isRequired,
        notifications: PropTypes.object,
        confirmations: PropTypes.object,
        is_active: PropTypes.string.isRequired,
        is_trash: PropTypes.string.isRequired,
        inDrawer: PropTypes.bool,
        toggleDrawer: PropTypes.func,
        file: PropTypes.exact(wpImagePropTypes),
        routerPath: PropTypes.string
    }

    static contextType = GeoContext

	/**
	 * Creates an instance of Form.
	 *
	 * @param {Form.propTypes} props The component's properties.
	 * @returns {Form} An instance of the component.
	 */
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.recaptchaRef = React.createRef();

        this.state = {
            userHasAttemptedToSubmit: false,
            isSubmitting: false,
            responseMessage: null,
            modalOpen: false,
            // ReCaptcha states.
            hasRecaptcha: false,
            googleVerificationKey: '',
            showPrivacyPolicy: false
        }

        this.resetForm = this.resetForm.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.afterOpenModal = this.afterOpenModal.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        if (this.recaptchaRef.current) {
            this.setState({ hasRecaptcha: true });
        }
    }

	/**
	 * Dynamically update input values in state on change.
	 *
	 * @param {React.SyntheticEvent} event The `onChange` event.
	 * @returns {void}
	 */
    handleInputChange(event) {
        const { target } = event;
        const { id, name, value, type } = target;

        if (type === 'checkbox' || type === 'consent') {
            return this.setState({
                [id]: this.state[id] === value ? null : value
            })
        }

        return this.setState({
            [name]: value
        });
    }

	/**
	 * Reset all form inputs to their default blank state,
	 * and scroll the user to the top of the screen.
	 *
	 * @returns {void}
	 */
    resetForm() {
        this.props.closeFormFunc();
        
        setTimeout(()=>{
            // Get the keys of all properties in state which start with "input_".
            const inputStates = Object.keys(this.state).filter((key) => (/^input_/).test(key));
            // Iterate over all input keys and set them to a blank string.
            inputStates.forEach((key) => this.setState({ [key]: '' }));
            // Reset ReCaptcha if applicable.
            if (this.state.hasRecaptcha) {
                this.recaptchaRef.current.reset();
            }

            // Reset the submission state so validation messages won't be shown.
            this.setState({ userHasAttemptedToSubmit: false, responseMessage: null }, () => smoothScroll(document.body));
        }, 1000);
    }

	/**
	 * Dynamically validate input values.
	 *
	 * @param {String} type The input type.
	 * @param {String} value The current input value.
	 * @returns {String|false} A validation message string, or `false` if no validation issues.
	 */
    validateInput(type, value) {
        let isInvalid = false;

        if (!this.state.userHasAttemptedToSubmit) {
            return false;
        }

        switch (type) {
            case 'email':
                // eslint-disable-next-line no-control-regex
                isInvalid = !(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/).test(value)
                if (isInvalid) {
                    return 'Please enter a valid email address.'
                }
                return false;
            case 'phone':
                isInvalid = !(/^(?:\+?(\d{1,3}))?([-.(]*(\d{3})[-.)]*)?((\d{3})[-.]*(\d{2,4})(?:[-.x]*(\d+))?)*$/).test(value) ||
                    value.length > 14 || value.length < 10;
                if (value && value.length && isInvalid) {
                    return 'Please enter a valid phone number (10-14 digits no spaces).'
                }
                return false;
            default:
                return false;
        }
    }

	/**
	 * Called immediately as the user submits the form.
	 * We check the form and input validity before continuing.
	 *
	 * @param {React.SyntheticEvent} event The `submit` event.
	 * @returns {void}
	 */
    handleInitialFormSubmit(event) {
        event.preventDefault();

        this.setState({
            userHasAttemptedToSubmit: true,
            isSubmitting: true
        }, () => {
            // Check form is valid (HTML5 validity) and all inputs are valid before proceeding.
            const isValid = this.formRef.current.checkValidity() &&
                this.formRef.current.getElementsByClassName('input--invalid').length === 0;

            if (isValid) {
                if (this.state.hasRecaptcha) {
                    const key = this.state.googleVerificationKey;
                    if (key === '' || key === undefined || key === null) { // eslint-disable-line no-undefined
                        return this.setState({ isSubmitting: false }, () =>
                            // eslint-disable-next-line no-alert
                            window.alert('Please complete the "I\'m not a robot" verification before submitting this form again.'))
                    }
                }

                return this.submitFormData();
            }

            return this.setState({
                isSubmitting: false
            });
        });
    }

	/**
	 * Stores the `verificationKey` returned from ReCaptcha to component state.
	 *
	 * @param {String} key The verification key returned by initial ReCaptcha completion.
	 * @returns {void}
	 */
    verifyRecaptcha(key) {
        this.setState({ googleVerificationKey: key }, () => {
            // Hack to ensure window doesn't scroll to footer after reCaptcha submission on iOS.
            if (window.innerWidth < 768 && ((/iPhone|iPod/).test(navigator.userAgent) && !window.MSStream)) {
                const element = document.getElementById('g-recaptcha');
                const target = element.offsetTop - window.innerWidth;
                window.scrollTo(0, target);
            }
        });
    }

	/**
	 * Submits the form data to the API.
	 *
	 * @returns {Promise} A promise to resolve a successful form submission.
	 */
    async submitFormData() {
        // Get all input-related values from state.
        const submission = Object.keys(this.state)
            .filter((key) => key.indexOf('input_') > -1)
            .reduce((obj, key) => {
                obj[key] = this.state[key];
                return obj;
            }, {});

        // Include the ReCaptcha key if applicable.
        if (this.state.hasRecaptcha) {
            submission['g-recaptcha-response'] = this.state.googleVerificationKey
        }

        // Post the data to the forms API.
        try {
            const response = await Axios.post(
                `${this.props.formURL}/api/forms/${this.props.id}`,
				submission
			);
            return this.handleDataResponse(response.data);
        } catch (error) {
            return this.handleSubmissionError(error);
        }
    }

	/**
	 * Called when a `200 OK` response is received from the form submission API.
	 *
	 * @param {{}} data The response from the API.
	 * @returns {void}
	 */
    handleDataResponse(data) {
        if (!data.is_valid) {
            return this.handleSubmissionError(data);
        }

        // Analytics.event({
        //     category: 'Forms',
        //     action: 'Successful Form Submission',
        //     label: `Form ID #${this.props.id} - "${this.props.title}"`
        // })

        if (data.confirmation_type === 'redirect') {
            return this.setState({
                isSubmitting: false,
                responseMessage: data.confirmation_message
            }, () => {
                window.location.href = data.confirmation_redirect
            });
        }

        return this.setState({
            modalOpen: true,
            isSubmitting: false,
            responseMessage: data.confirmation_message
        });
    }

	/**
	 * Called when a non-200 response is received from the forms API.
	 * Pops an error message to a modal to advise the user of the issue.
	 *
	 * @param {{}} error The error sent by the API.
	 * @returns {void}
	 */
    handleSubmissionError(error) {
        let errorMessage = '<p>Sorry, there was an error submitting your form. Please try again later.</p>';

        if (JSON.stringify(error).indexOf('reCAPTCHA was invalid') > -1) {
            errorMessage = '<p>Sorry, there was an error verifying that you\'re not a robot. Please ensure you have completed the "I\'m not a robot" verification correctly and can see a green tick, before submitting the form again.</p>';
        }

        // Analytics.event({
        //     category: 'Forms',
        //     action: 'Failed Form Submission',
        //     label: `Form ID #${this.props.id} - "${errorMessage}"`
        // })

        return this.setState({
            modalOpen: true,
            isSubmitting: false,
            responseMessage: errorMessage
        }, () => Logger(error, window.location.href));
    }

	/**
	 * Toggle the modal state.
	 *
	 * @returns {void}
	 */
    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        }, () => {
            if (this.props.inDrawer && this.props.toggleDrawer && !this.state.modalOpen) {
                this.props.toggleDrawer();
            }
        });
    }

	/**
	 * Function to perform after the modal is opened.
	 *
	 * @returns {void}
	 */
    afterOpenModal() {
        // Auto-close the popup after timeout, then reset the form.
        setTimeout(() => {
            this.setState({
                modalOpen: false
            }, () => this.resetForm());
        }, 10000);
    }

	/**
	 * Check if the field is required but the value is empty.
	 *
	 * @param {Boolean} isRequired If the field is a required field.
	 * @param {String}  name       The field's name attribute.
	 * @param {String}  type       The type of field.
	 * @param {Array}   choices    The array of choices (if applicable).
	 * @returns {Boolean} True if the field is required, the form has been submitted but the value is empty.
	 */
    checkIfRequiredAndEmpty(isRequired, name, type, choices) {
        const { userHasAttemptedToSubmit } = this.state;
        let requiredAndEmpty = userHasAttemptedToSubmit &&
            isRequired &&
            (!this.state[name] || this.state[name] === '');

        if (type === 'checkbox') {
            requiredAndEmpty = userHasAttemptedToSubmit &&
                isRequired &&
                choices.filter((choice, index) => {
                    const fieldID = `${name}_${index + 1}`;
                    return this.state[fieldID] && this.state[fieldID] === choice.value;
                }).length === 0;
        }

        if (type === 'consent') {
            requiredAndEmpty = userHasAttemptedToSubmit &&
                isRequired &&
                (!this.state[`${name}_1`] || this.state[`${name}_1`] === '0');
        }

        return requiredAndEmpty;
    }

	/**
	 * Render a field or field group.
	 *
	 * @param {{}} field The field's data object.
	 * @returns {JSX.Element} The field wrapper and input markup.
	 */
    renderField(field) { /* eslint complexity: ["error", 30] */
        const geo = this.context;
        const { id, label, labelPlacement, type, description, choices, isRequired, conditionalLogic, errorMessage } = field;
        let { cssClass } = field;
        let defaultValue = '';
        const isDisabled = cssClass.indexOf('input--disabled') > -1;
        const name = `input_${id}`;
        const invalidMessage = this.validateInput(type, this.state[name]);
        const requiredAndEmpty = this.checkIfRequiredAndEmpty(isRequired, name, type, choices);

        if (type === 'radio') {
            cssClass += ' form__radio-wrapper';
        }

        if (invalidMessage || requiredAndEmpty) {
            cssClass += ' input--invalid';
        }

		/**
		 * Conditional Logic per-field.
		 *
		 * @since v1.2.0
		 * @const {String} actionType Either 'show' or 'hide'.
		 * @const {String} logicType  Either 'any' or 'all'.
		 * @const {Array<{fieldId: String, operator: String, value: String}>} rules An array of conditions.
		 */
        if (conditionalLogic) {
            const { actionType, logicType, rules } = conditionalLogic;
            const evaluatedRules = [];

            // Evaluate each rule first and add a bool value as to whether it has been met or not.
            rules.forEach((rule, index) => {
                const valueToEvaluateAgainst = this.state[`input_${rule.fieldId}`];

                switch (rule.operator) {
                    case 'is':
                        evaluatedRules[index] = valueToEvaluateAgainst === rule.value;
                        break;
                    case 'isnot':
                        evaluatedRules[index] = valueToEvaluateAgainst !== rule.value;
                        break;
                    case '>':
                        evaluatedRules[index] = valueToEvaluateAgainst > rule.value;
                        break;
                    case '<':
                        evaluatedRules[index] = valueToEvaluateAgainst < rule.value;
                        break;
                    case 'contains':
                        evaluatedRules[index] = valueToEvaluateAgainst.indexOf(rule.value) > -1;
                        break;
                    case 'starts_with':
                        evaluatedRules[index] = valueToEvaluateAgainst.substring(0, rule.value.length) === rule.value;
                        break;
                    case 'ends_with':
                        evaluatedRules[index] = valueToEvaluateAgainst.substring(
                            valueToEvaluateAgainst.length - rule.value.length,
                            valueToEvaluateAgainst.length
                        ) === rule.value;
                        break;
                    default:
                        break;
                }
            })

            const conditionsHaveBeenMet = logicType === 'any'
                // Evaluates to true if ANY of the rules match.
                ? evaluatedRules.findIndex((value) => value === true) > -1
                // Evaluates to true if ALL of the rules match.
                : evaluatedRules.findIndex((value) => value === false) === -1;

            // If we want to show this field only when our conditions have been met.
            if (actionType === 'show' && !conditionsHaveBeenMet) {
                return null;
            }
            // If we want to hide this field only when our conditions have been met.
            if (actionType === 'hide' && conditionsHaveBeenMet) {
                return null;
            }
        }

		/**
		 * Populate field values dynamically from query string where it has been enabled.
		 * @since v1.2.0
		 */
        if (field.allowsPrepopulate && field.inputName) {
            const dynamicValue = getUrlParameter(this.props.routerPath, field.inputName);

            if (dynamicValue) {
                defaultValue = dynamicValue;
            }
        }

        switch (type) {
            case 'captcha':
                return this.renderRecaptcha(id);
            case 'consent':
                return <div key={id} className="form__input-group">
                    <Input
                        type="checkbox"
                        id={`${name}_1`}
                        name={name}
                        label={field.checkboxLabel}
                        containerClass={cssClass}
                        value="1"
                        onChange={(e) => this.handleInputChange(e)}
                        checked={Boolean(this.state[`${name}_1`] && this.state[`${name}_1`] === '1')}
                        required={isRequired}
                        disabled={isDisabled}
                    />
                    {this.renderContentBelowInput(description, invalidMessage, errorMessage, requiredAndEmpty, label)}
                </div>
            case 'checkbox':
            case 'radio':
                return <div key={id} className="form__input-group">
                    {labelPlacement !== 'hidden_label' &&
                        <p>{label}</p>
                    }
                    {choices && choices.map((choice, index) => {
                        const fieldID = `${name}_${index + 1}`;
                        return <Input
                            type={type}
                            key={index}
                            id={fieldID}
                            name={name}
                            label={choice.text}
                            containerClass={cssClass}
                            value={choice.value}
                            onChange={(e) => this.handleInputChange(e)}
                            checked={type === 'radio'
                                ? this.state[name] === choice.value
                                : Boolean(this.state[fieldID] && this.state[fieldID] === choice.value)
                            }
                            required={type === 'radio' ? isRequired : null}
                            disabled={isDisabled}
                        />
                    })}
                    {this.renderContentBelowInput(description, invalidMessage, errorMessage, requiredAndEmpty, label)}
                </div>
            case 'hidden':
                if (this.props.file) {
                    if (id === 6) {
                        defaultValue = this.props.file.url;
                    } else if (id === 7) {
                        defaultValue = this.props.file.title;
                    }
                }

                return <Input
                    type={type}
                    key={id}
                    id={name}
                    name={name}
                    containerClass="hidden"
                    value={this.state[name]}
                    default={defaultValue}
                    onChange={(e) => this.handleInputChange(e)}
                />
            case 'html':
                return <div key={id} className="form__field-wrapper html">
                    <RichText content={field.content} alignment="center" uncontained />
                </div>
            default:
                return <Input
                    type={type === 'phone' ? 'tel' : type}
                    key={id}
                    id={name}
                    name={name}
                    label={isRequired ? `${label} *` : label}
                    containerClass={cssClass}
                    default={type === 'select' && label === 'Region' ? geo.region : defaultValue}
                    value={this.state[name]}
                    onChange={(e) => this.handleInputChange(e)}
                    rows={type === 'textarea' ? 4 : null}
                    choices={choices}
                    required={isRequired}
                    disabled={isDisabled}>
                    {this.renderContentBelowInput(description, invalidMessage, errorMessage, requiredAndEmpty, label)}
                </Input>;
        }
    }

	/**
	 * Render the Google ReCaptcha component.
	 *
	 * @param {Number} id The field ID of the captcha.
	 * @returns {JSX.Element} The required ReCaptcha markup.
	 */
    renderRecaptcha(id) {
        return <div key={id} className="form__field-wrapper" id="g-recaptcha">
            <ReCAPTCHA ref={this.recaptchaRef} sitekey={siteKey} onChange={(token) => this.verifyRecaptcha(token)} />
        </div>;
    }

	/**
	 * Render the various bits and bobs of helper/error text below the input.
	 *
	 * @param {String}  description      The field description text.
	 * @param {String}  invalidMessage   The text to show when the field is invalid.
	 * @param {String}  errorMessage     The text to show when the field has an error.
	 * @param {Boolean} requiredAndEmpty If the field is required but the value is empty.
	 * @param {String}  label            The field label.
	 * @returns {JSX.Element} A Fragment containing one or more helper text snippets.
	 */
    renderContentBelowInput(description, invalidMessage, errorMessage, requiredAndEmpty, label) {
        return (
            <>
                {description &&
                    <small className="body--small input__helper">{description}</small>
                }
                {invalidMessage &&
                    <small className="body--small input__error">{errorMessage || invalidMessage}</small>
                }
                {requiredAndEmpty &&
                    <small className="body--small input__error">{`${label} is required.`}</small>
                }
            </>
        )
    }

    /**
     * These two functinos are responsible for opening
     * and closing our privacy policy modal on clicking events.
     */
    handleClose() {
        this.setState({ showPrivacyPolicy: false });
    }

    handleShow() {
        this.setState({ showPrivacyPolicy: true });
    }

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The component markup.
	 */
    render() {
        const { title, description, fields, button } = this.props;
        const buttonClassName = classNames(
            'button',
            'button--block',
            this.state.isSubmitting && 'button--progress'
        )

        // Don't return anything if the form has been disabled or trashed in WP Admin.
        if (this.props.is_active === '0' || this.props.is_trash === '1') {
            return null;
        }

        return [
			!this.state.responseMessage && (
				<p key='1' className='body--eyebrow text--center'>
					Download
				</p>
			),
			<section key='2' id='form' className='section' aria-label={title}>
				<div className='container'>
					{!this.state.responseMessage ? (
						[
                            <form
                                key="the-form"
								ref={this.formRef}
								className='component form form--needs-validation boxed'
								noValidate
								onSubmit={e => this.handleInitialFormSubmit(e)}
							>
								<div className='form__header text--center'>
									<h2 className='heading--3'>
										{parseSpecialCharacters(title)}
									</h2>
									<p className='body--1'>{description}</p>
								</div>
								<div className='form__body'>
									<small className='body--small form__helper-text'>
										* Required fields
									</small>
									<div className='form__fields'>
										{fields &&
											fields.map(field =>
												this.renderField(field)
											)}
										<div className='form__submit-wrapper text--center'>
											<button
												className={buttonClassName}
												type='submit'
											>
												{this.state.isSubmitting ? (
													<>
														<span>&nbsp;</span>
														<span
															className='daifuku-icon icon--progress icon--sm'
															role='img'
															aria-label='submitting'
														>
															<svg
																width='20px'
																height='20px'
																viewBox='0 0 20 20'
																version='1.1'
																xmlns='http://www.w3.org/2000/svg'
															>
																<g
																	id='Style'
																	stroke='none'
																	strokeWidth='1'
																	fill='none'
																	fillRule='evenodd'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																>
																	<g
																		id='Buttons'
																		transform='translate(-556.000000, -650.000000)'
																		stroke='#fff'
																	>
																		<g
																			id='icon'
																			transform='translate(554.000000, 648.000000)'
																		>
																			<path
																				d='M7.25,3.7726 L7.25,3.77154444 C4.41055556,5.41398889 2.5,8.48354444 2.5,11.9996 C2.5,17.2467667 6.75283333,21.4996 12,21.4996 C17.2471667,21.4996 21.5,17.2467667 21.5,11.9996 C21.5,6.75348889 17.2471667,2.4996 12,2.4996'
																				id='lineart'
																			></path>
																		</g>
																	</g>
																</g>
															</svg>
														</span>
													</>
												) : (
													button.text
												)}
											</button>
										</div>
										<div className='form__footer rich-text text--center'>
											<small className='body--small'>
												Your information will be used in
												accordance with our&nbsp;
												<span className="privacy-policy-button" onClick={this.handleShow}>
													Privacy Policy.
												</span>
											</small>
										</div>
									</div>
								</div>
							</form>,
							<div key="close-button" className='form-pop-out__close'>
								<button onClick={this.props.closeFormFunc}>
									<span
										className='daifuku-icon icon--close icon--sm'
										role='img'
										aria-label='close'
									>
										<svg viewBox='0 0 23 23' fill='#4c565f'>
											<g data-name='Layer 2'>
												<path
													fillRule='evenodd'
													d='M21.59 0L11.5 10.09 1.41 0 0 1.42 10.09 11.5 0 21.59 1.41 23 11.5 12.91 21.59 23 23 21.59 12.91 11.5 23 1.42 21.59 0z'
													data-name='Layer 1'
												></path>
											</g>
										</svg>
									</span>
								</button>
							</div>,
                            <Modal
                                key="privacy-policy-modal"                
								className='privacy-policy-modal'
								open={this.state.showPrivacyPolicy}
								onClose={this.handleClose}
								center
							>
								<Privacy />
							</Modal>
						]
					) : (
						<div className='rich-text text--center'>
							<span
								className='daifuku-icon icon--message2 icon--md'
								role='img'
								aria-label='message2'
							>
								<svg viewBox='0 0 32.67 32.67' fill='#4c565f'>
									<g data-name='Layer 2'>
										<path
											d='M8.83 32.67h-.05a.53.53 0 01-.17 0l-.14-.11a.42.42 0 01-.11-.18.53.53 0 010-.22.29.29 0 010-.09l3.22-11.28L.25 14.27A.53.53 0 010 13.8a.5.5 0 01.31-.43L32 0H32.24a.45.45 0 01.31.14.49.49 0 01.14.3.45.45 0 010 .11.2.2 0 010 .11L21 32.34a.51.51 0 01-.88.12L14.75 25l-5.51 7.45-.06.08a.58.58 0 01-.18.11.51.51 0 01-.17.03zm6.35-8.82l5.18 7.26L30.91 2.46 12.82 20.55l2.33 3.27zm-2.82-2.22l-2.28 8 4.06-5.52zM1.63 13.9l10.45 6L30 2z'
											fill='#4c565f'
											opacity='0.87'
											data-name='Layer 1'
										></path>
									</g>
								</svg>
							</span>
							<div className='default-text-color'
								dangerouslySetInnerHTML={{
									__html: this.state.responseMessage
								}}
							/>
							<button
								onClick={this.resetForm}
								className={buttonClassName}
							>
								{"Thanks, I'm done."}
							</button>
						</div>
					)}
				</div>
			</section>
		];
    }

}

export default Form;
