// Vendor libs.
import React, { Component } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';

// Custom utilities.
import Data from '../content/data';
import { getSlug } from '../utils/Helpers';
import { alignmentPropTypes } from '../utils/types.spec';

/**
 * Component #6 — Rich Text.
 * Allows for the rich formatting of text content,
 * including common structural treatments like lists,
 * and formatting treatments like bold and italic text.
 *
 * Uses `html-react-parser` to convert a string of HTML into
 * React components. We also use this module to replace all inline links
 * with `RouterLink`s, if they point to on-site content.
 *
 * @author Charles Harwood
 * @category Components
 * @extends {Component}
 */
class RichText extends Component {

	static propTypes = {
		content: PropTypes.string.isRequired,
		boxed: PropTypes.bool,
		alignment: alignmentPropTypes,
		uncontained: PropTypes.bool
	}

	static defaultProps = {
		boxed: false,
		alignment: 'left',
		uncontained: false
	}

	/**
	 * Scroll smoothly to an anchor point.
	 *
	 * @param {React.SyntheticEvent} event The click event.
	 * @param {String} anchor The href pointing to the anchor.
	 * @returns {void}
	 */
	anchorScroll(event, anchor) {
		if (event) {
			event.preventDefault();
		}

		if (anchor) {
			const { smoothScroll } = require('../utils/Helpers');
			const id = anchor.replace('#', '');
			const el = document.getElementById(id);

			if (el) {
				smoothScroll(el);
			}
		}
	}

	/**
	 * Parse our HTML string and return React components.
	 * Also converts inline links into `RouterLink` components.
	 *
	 * @param {String} html The HTML string to parse and convert.
	 * @returns {React.DetailedReactHTMLElement|Array<React.DetailedReactHTMLElement>|String}
	 * ReactElement or Array of ReactElements on successful parse, or string when `string` cannot be parsed as HTML.
	 */
	turnLinksIntoRouterLinks(html) {
		return Parser(html, {
			replace: ({ name, attribs, children }) => {
				if (!attribs) {
					return;
				}

				// If the element is a link...
				if (name === 'a') {
					// and links to our site and is not a media file, it's morphin' time.
					if (attribs.href.indexOf(Data.formSiteUrl) > -1 && attribs.href.indexOf('api/asset') < 0) {
						let slug = getSlug(attribs.href);
						if (Data.formSiteUrl.indexOf(slug) > -1) {
							slug = 'home';
						}
						// WP Content blob will give us `class` but since we're Reactifying,
						// we need to swap that out for `className`;
						if (attribs.class) {
							attribs.className = attribs.class;
							delete attribs.class;
						}
						// eslint-disable-next-line consistent-return
						return (
							<a {...attribs}>
								{children && children.length &&
									children.map(({ data }) => data)
								}
							</a>
						)	
					}
					// If the link is external, add a class so we can style it.
					if (attribs.href.indexOf('http') > -1 && attribs.target === '_blank') {
						if (attribs.class) {
							attribs.class += ' link--external';
						} else {
							attribs.class = 'link--external';
						}
					}
					// Finally, if the link is an anchor, let's hook up our smooth scrolling function.
					if (attribs.href.indexOf('#') === 0 && attribs.href.length > 1) {
						// eslint-disable-next-line consistent-return
						return <a { ...attribs } onClick={ (e) => this.anchorScroll(e, attribs.href) }>
							{ children && children.length &&
								children.map(({ data }) => data)
							}
						</a>
					}
				}
			}
		})
	}

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The component markup.
	 */
	render() {
		const { content, boxed, alignment, uncontained } = this.props;

		const markup = <article className={ `component rich-text text--${alignment}${boxed ? ' boxed' : ''}` }>
			{ this.turnLinksIntoRouterLinks(content) }
		</article>

		if (uncontained) {
			return markup;
		}

		return (
			<section className="section">
				<div className="container">
					{ markup }
				</div>
			</section>
		);
	}

}

export default RichText;
