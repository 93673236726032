// Vendor libs.
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Custom Icon component.
 * Renders a React component from an SVG file.
 *
 * @author Charles Harwood
 * @class
 * @category Components
 * @param {Icon.propTypes} props The component's properties.
 * @returns {JSX.Element} The icon's HTML and inline-SVG markup.
 */
const Icon = (props) => {
	const SVGIcon = require(`./icons/ico-${props.name}.svg`).default;

	return (
		<span className={ `daifuku-icon icon--${props.name} icon--${props.size}` } role="img" aria-label={ props.name }
			onClick={ props.onClick }>
			{ props.colour
				? <SVGIcon fill={ props.colour } />
				: <SVGIcon />
			}
		</span>
	)
}

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOf([
		'sm', 'md', 'lg', 'social', 'full-width', 'full-height'
	]).isRequired,
	colour: PropTypes.string,
	onClick: PropTypes.func
}

Icon.defaultProps = {
	size: 'sm'
}

export default Icon;
