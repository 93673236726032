/* eslint-disable camelcase */
import PropTypes from 'prop-types';

/**
 * Static types for all React.Component children.
 *
 * @category Types
 */
export const childComponentTypes = PropTypes.oneOfType([
	PropTypes.node,
	PropTypes.arrayOf(PropTypes.node),
	PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node))
]);

/**
 * Static types for `next/router`.
 *
 * @category Types
 */
export const routerPropTypes = PropTypes.exact({
	asPath: PropTypes.string,
	back: PropTypes.func,
	beforePopState: PropTypes.func,
	components: PropTypes.object,
	events: PropTypes.object,
	pathname: PropTypes.string,
	prefetch: PropTypes.func,
	push: PropTypes.func,
	query: PropTypes.object,
	reload: PropTypes.func,
	replace: PropTypes.func,
	route: PropTypes.string
});

/**
 * Static types for tags (element names).
 *
 * @category Types
 */
export const tagPropTypes = PropTypes.oneOfType([
	PropTypes.func,
	PropTypes.string,
	PropTypes.shape({
		$$typeof: PropTypes.symbol,
		render: PropTypes.func
	}),
	PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
		PropTypes.shape({
			$$typeof: PropTypes.symbol,
			render: PropTypes.func
		})
	]))
]);

/**
 * Static types for all WP Image objects.
 *
 * @category Types
 */
export const wpImagePropTypes = {
	ID: PropTypes.number,
	id: PropTypes.number,
	title: PropTypes.string,
	filename: PropTypes.string,
	filesize: PropTypes.number,
	url: PropTypes.string.isRequired,
	link: PropTypes.string,
	alt: PropTypes.string.isRequired,
	author: PropTypes.string,
	description: PropTypes.string,
	caption: PropTypes.string,
	name: PropTypes.string,
	status: PropTypes.string,
	uploaded_to: PropTypes.number,
	date: PropTypes.string,
	modified: PropTypes.string,
	menu_order: PropTypes.number,
	mime_type: PropTypes.string,
	type: PropTypes.string,
	subtype: PropTypes.string,
	icon: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	sizes: PropTypes.exact({
		thumbnail: PropTypes.string,
		'thumbnail-width': PropTypes.number,
		'thumbnail-height': PropTypes.number,
		medium: PropTypes.string,
		'medium-width': PropTypes.number,
		'medium-height': PropTypes.number,
		medium_large: PropTypes.string,
		'medium_large-width': PropTypes.number,
		'medium_large-height': PropTypes.number,
		large: PropTypes.string,
		'large-width': PropTypes.number,
		'large-height': PropTypes.number
	})
};

/**
 * Static types for `/wp-json/` REST endpoint.
 *
 * @category Types
 */
export const settingsPropTypes = PropTypes.exact({
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired
});

/**
 * Static types for `/wp-json/menus/v1/` REST endpoint.
 *
 * @category Types
 */
export const menuPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		ID: PropTypes.number.isRequired,
		classes: PropTypes.arrayOf(PropTypes.string),
		description: PropTypes.string,
		menu_item_parent: PropTypes.string,
		menu_order: PropTypes.number,
		object: PropTypes.string.isRequired,
		object_id: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		target: PropTypes.string,
		title: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	})
).isRequired;

/**
 * Static types for our combined menu data.
 *
 * @category Types
 */
export const menusPropTypes = PropTypes.exact({
	header: menuPropTypes,
	footer: menuPropTypes,
	copyrightLinks: menuPropTypes,
	sitemap: menuPropTypes
});

/**
 * Static types for Yoast SEO data.
 *
 * @category Types
 */
export const yoastPropTypes = PropTypes.exact({
	yoast_wpseo_focuskw: PropTypes.string,
	yoast_wpseo_title: PropTypes.string,
	yoast_wpseo_metadesc: PropTypes.string,
	yoast_wpseo_linkdex: PropTypes.string,
	yoast_wpseo_metakeywords: PropTypes.string,
	'yoast_wpseo_meta-robots-noindex': PropTypes.string,
	'yoast_wpseo_meta-robots-nofollow': PropTypes.string,
	'yoast_wpseo_meta-robots-adv': PropTypes.string,
	yoast_wpseo_canonical: PropTypes.string,
	yoast_wpseo_redirect: PropTypes.string,
	'yoast_wpseo_opengraph-title': PropTypes.string,
	'yoast_wpseo_opengraph-description': PropTypes.string,
	'yoast_wpseo_opengraph-image': PropTypes.string,
	'yoast_wpseo_twitter-title': PropTypes.string,
	'yoast_wpseo_twitter-description': PropTypes.string,
	'yoast_wpseo_twitter-image': PropTypes.string
});

/**
 * Static types for ACF Layout data.
 *
 * @category Types
 */
export const acfPropTypes = {
	layout: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.arrayOf(PropTypes.shape({
			acf_fc_layout: PropTypes.string.isRequired
		}))
	])
};

/**
 * Static types for `/wp-json/better-rest-endpoints/v1/post/{slug|id}` REST endpoint.
 *
 * @category Types
 */
export const postPropTypes = {
	acf: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape(acfPropTypes)
	]),
	author: PropTypes.string,
	author_id: PropTypes.number,
	author_nicename: PropTypes.string,
	category_ids: PropTypes.arrayOf(PropTypes.number),
	category_names: PropTypes.arrayOf(PropTypes.string),
	content: PropTypes.string,
	date: PropTypes.string,
	excerpt: PropTypes.string,
	id: PropTypes.number.isRequired,
	media: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object
	]),
	parent: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string
	]),
	permalink: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	tag_ids: PropTypes.arrayOf(PropTypes.number),
	tag_names: PropTypes.arrayOf(PropTypes.string),
	template: PropTypes.string,
	title: PropTypes.string.isRequired,
	yoast: PropTypes.oneOfType([
		PropTypes.bool,
		yoastPropTypes
	])
};

/**
 * Static types for `/wp-json/acf/v3/options/options` REST endpoint.
 *
 * @category Types
 */
export const optionsPropTypes = PropTypes.oneOfType([
	PropTypes.array,
	PropTypes.object
]);

/**
 * Static types for our `MobileContext`.
 *
 * @category Types
 */
export const mobilePropTypes = PropTypes.oneOfType([
	PropTypes.bool,
	PropTypes.exact({
		isTablet: PropTypes.bool,
		isPhone: PropTypes.bool
	})
]);

/**
 * Static types for `/wp-json/wp/v2/categories/{slug|id}` REST endpoint.
 *
 * @category Types
 */
export const categoryPropTypes = PropTypes.shape({
	id: PropTypes.number.isRequired,
	link: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	parent: PropTypes.number.isRequired,
	slug: PropTypes.string.isRequired,
	taxonomy: 'category'.isRequired
});

/**
 * Static types for any `alignment` properties.
 *
 * @category Types
 */
export const alignmentPropTypes = PropTypes.oneOf([
	'left',
	'center',
	'right'
]);

/**
 * Static types for any buttons or links pointing to a `post_object` target.
 *
 * @category Types
 */
export const buttonPostObjectPropTypes = PropTypes.oneOfType([
	PropTypes.shape({
		id: PropTypes.number.isRequired,
		slug: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		excerpt: PropTypes.string,
		image: PropTypes.string,
		permalink: PropTypes.string.isRequired
	}),
	PropTypes.bool,
	PropTypes.array
]);

/**
 * Static types for all buttons.
 *
 * @category Types
 */
export const buttonPropTypes = {
	label: PropTypes.string,
	target_type: PropTypes.oneOf([
		'internal', 'external', 'download', 'subscribe', 'share'
	]),
	url: PropTypes.string,
	target_object: buttonPostObjectPropTypes,
	target_file: PropTypes.oneOfType([
		PropTypes.bool, // ACF returns false if no file provided.
		PropTypes.exact(wpImagePropTypes) // Otherwise we get file URL string.
	]),
	colour: PropTypes.string,
	icon: PropTypes.string,
	style: PropTypes.oneOf([
		'outline',
		'solid'
	])
};

/**
 * Static types for NewsletterContext.
 *
 * @category Types
 */
export const newsletterPropTypes = PropTypes.exact({
	drawerOpen: PropTypes.bool.isRequired,
	toggleNewsletterDrawer: PropTypes.func.isRequired
});

/**
 * Static types for RegisterContext.
 *
 * @category Types
 */
export const registerPropTypes = PropTypes.exact({
	drawerOpen: PropTypes.bool.isRequired,
	file: PropTypes.object,
	toggleRegisterDrawer: PropTypes.func.isRequired
});
