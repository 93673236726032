// Copy of jQuery's $(window).width() function
const windowWidth = () => {
    var docElemProp = window.document.documentElement.clientWidth,
        body = window.document.body;
    // eslint-disable-next-line
    return window.document.compatMode === "CSS1Compat" && docElemProp || body && body.clientWidth || docElemProp;
}

// Basic quick and dirty screen size grouping
const ScreenSize = () => {

    if (windowWidth() > 1367) {
        return "desktop"
    } else if (windowWidth() > 1025) {
        return "ipad-pro"
    } else {
        return "ipad"
    }
}
  

export {ScreenSize}