import React from "react";
import PropTypes from "prop-types";
import PointCard from "./point-card";
import ScrollBox from "./scroll-box";

class PointIndex extends React.Component {
	constructor(props) {
		super();
	}

	state = {
		isOpen: false
	};


	render() {
		return (
			<div className="point-index" >
				<ScrollBox transition={this.props.transition}>
					{this.props.points.map((point, index) =>
						<PointCard pageChangeStatus={this.props.pageChangeStatus} region={this.props.region} key={index} point={point} id={index+1} viewPoint={this.props.viewPoint} />				              
					)}
				</ScrollBox>
			</div>
		);
	}
}

PointIndex.propTypes = {
    points: PropTypes.array,
    region: PropTypes.number,
	viewPoint: PropTypes.func
};

export default PointIndex;
